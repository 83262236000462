import {
  Row,
  Col,
  Nav,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';
import Signature from "../../components/Signature/Signature";
import { emailValidation } from "../../utils/Helper";
import DropdownForSubQuestion from "../../components/DropdownfromApi/Dropdown";
import DropdownForEmployeeSubQuestion from "../../components/DropdownfromApi/DropdownEmployee";
import DropdownFromApi from "../../components/DropdownfromApi/DropdownforQuestion";
import {
  getDataInFormat,
  removeTags,
  formatAMPM,
  getUrlWithQueryString,
  convertTemperature,
  convertMilesPerHour,
  getTemperatureColorFilter,
  getWindGustColorFilter,
} from "../../utils/Helper";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  uploadMultiImage,
  detectAI,
  detectAIALL,
} from "../../api/CreateiJHA.api";
import ic_trauma_center from "../../assets/Icons/ic_trauma_center.png";
import { checkImageData } from "../../api/ImageMetaData.api";
import { GetAutoSuggestions } from "../../api/AutoSuggestions.api";
import { GetHazardTopic } from "../../api/HazardTopic.api";
import {
  DECRYPTED_DATA,
  EMP_ID,
  IMG_TOKEN,
  UserGroupId,
} from "../../utils/Variable";
import Resizer from "react-image-file-resizer";
import trashcan from "../../assets/Icons/trashcan.svg";
import plus from "../../assets/Icons/plus.svg";
import ic_ai from "../../assets/Icons/ic_ai.png";
import PhoneButton from "../../assets/Icons/PhoneButton.png";
import ic_partly_sunny from "../../assets/Icons/ic_partly_sunny.png";
import "./SectionCompForIJHA.scss";
// import { section17Question } from '../../utils/Helper'
import ic_rain from "../../assets/Icons/ic_rain.png";
import ic_sunny from "../../assets/Icons/ic_sunny.png";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../components/Loading/Loading";
import ic_temperature from "../../assets/Icons/ic_temperature.png";
import ic_wind from "../../assets/Icons/ic_wind.png";
import ic_uv_index from "../../assets/Icons/ic_uv_index.png";
import ic_sky from "../../assets/Icons/ic_sky.png";
import ic_visibility from "../../assets/Icons/ic_visibility.png";
import ic_humidity from "../../assets/Icons/ic_humidity.png";
import ic_pressure from "../../assets/Icons/ic_pressure.png";
import ic_precipitation from "../../assets/Icons/ic_precipitation.png";
import position from "../../assets/Icons/position.svg";
import ModalCertificateSiteObsrForm from "../../components/Modal/ModalCertificateSiteObsrForm";
import CertificateListSiteObsrForm from "../../components/CertificateList/CertificateListSiteObsrForm";
import _, { cloneDeep } from "lodash";
import Select from "react-select";
import { useForm } from "react-hook-form";
import map_directions from "../../assets/Icons/map-directions.svg";
import DailyTaskforSIF from "../../components/DailyTask/DailyTaskforSIF";
import GenericQuestionTypes from "../../components/GenericQuestionTypes/GenericQuestionTypes";
import { getInspectionWeatherApi } from "../../api/Common.api";
import info from "../../assets/Icons/info.svg";
export default function SectionCompForIJHA({
  videodisplayes,
  setvideodisplayes,
  initiateVideoCall1,
  sectionDeficiency,
  sectionCustomCorrective,
  getWeatherHours,
  questions_obj,
  sectionId,
  onChangeFormTemplate,
  onChangeEmployee,
  sectionName,
  modalMapHandler1,
  getLongitude,
  getLatitude,
  getAddress,
  getReportID,
  changeFormTemplateData,
  getCertificateOptionListD,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
  changeFormTemplateDataforSpecificSection,
  radioValue,
  setRadioValue,
  setCertifySection,
  removeImgData,
  form_template,
  getProceed,
  setLoading,
  setForm_template,
  getCrewLead,
  emailError,
  setEmailError,
  questions_obj_key,
  setWeatherHours,
  sendData,
  entiresection,
  setErrMsg,
  typeofstructure,
  getImgMetaData,
  setImgMetaData,
  getEmployeeDropdownval,
  getApiResponseEmployee,
  modalMapCustomHandler
}) {
  // '19/08/2022'
  const [getDataMdl1, setDataMdl1] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const loc = useLocation();
  const [UpdatedValueofDropdown, setUpdatedValueofDropdown] = useState(null);

  const [SelectedOptionofDropdown, setSelectedOptionofDropdown] =
    useState(null);

  const [SelectedEmpOptionofDropdown, setSelectedEmpOptionofDropdown] =
    useState(null);

  const [UpdatedValueofDropdownApi, setUpdatedValueofDropdownApi] =
    useState(null);
  const [SelectedOptionofDropdownApi, setSelectedOptionofDropdownApi] =
    useState(null);
  const [getFieldType, setFieldType] = useState();
  const [showMdl1, setShowMdl1] = useState(false);
  const [getSignBase64Uri, setSignBase64Uri] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  const [loading1, setLoading1] = useState(true);
  const [getHCMOptions, setHCMOptions] = useState([]);
  const [getWeatherupdate, setWeatherUpdate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [myState, setMyState] = useState([]);
  const [timeout, updateTimeout] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [dialoguetopicvalues, setdialoguetopicvalues] = useState("");
  // Array of all options
  const [DropdownOptions, setDropdownOptions] = useState(null);

  const [getLoadingtext, setLoadingtext] = useState(true);
  const [getSelectedOptionsMulti1, setSelectedOptionsMulti1] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);

  const [getCommentMulti1, setCommentMulti1] = useState([]);
  const [getImgAiDetectKeys2, setImgAiDetectKeys2] = useState({});
  const [getForecasts, setForecasts] = useState([]);

  const [getProceed12, setProceed12] = useState(0);
  const [getCrewLeaddata, setCrewLeaddata] = useState(0);
  const [getUploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [getAddMoreDailyTask, setAddMoreDailyTask] = useState([]);

  const [getError1, setError1] = useState("");
  const [getImgAiDetectKeys, setImgAiDetectKeys] = useState({});
  const [getAIData, setAIData] = useState({});

  const [getUploadedImgUrls1, setUploadedImgUrls1] = useState([]);
  const [getUploadedImgUrls12, setUploadedImgUrls12] = useState([]);

  const [getSetP, setSetP] = useState(false);
  const [getState1, setState1] = useState(false);
  const [getImgUrls, setImgUrls] = useState({});
  const [getUploadedImgUrlsofImages, setUploadedImgUrlsofImages] = useState([]);
  const [getWeatherStatus, setWeatherStatus] = useState(false);
  const [warningmessagerequired, setwarningmessagerequired] = useState({
    WindGust: "",
    Temperature: "",
    toastErrMsg: false,
  });

  useEffect(() => {
    if (getWeatherStatus === true) {
      async function fetchData() {
        await getInspectionWeatherApi(setForm_template, form_template, sendData, setWeatherHours);
      }
      fetchData();
    }
  }, [radioValue, getWeatherStatus]);

  const [selectedOption, setSelectedOption] = useState(() => {
    if (
      form_template.some(
        (section) => section.sectionName === "Site Safety Walk"
      )
    ) {
      const pageIndexofSiteSafetyWalk = form_template.findIndex(
        (item) => item.sectionName === "Site Safety Walk"
      );
      const answer =
        form_template[pageIndexofSiteSafetyWalk].questions[15].answer;
      if (!answer) return {};
      return { label: answer, value: answer };
    } else {
      return {};
    }
  });

  const [selectedOptions, setSelectedOptions] = useState(() => {
    return selectedOption.value ? [selectedOption] : [];
  });

  let SectionChange = {};

  if (form_template.some((section) => section.sectionId === "17")) {
    const pageIndexofDeficiency = form_template.findIndex(
      (item) => item.sectionId === "17"
    );
    form_template[pageIndexofDeficiency].visibility = form_template.some(
      (section) =>

        section.questions.some(

          (question) =>

            question?.questionType === "Radio" &&
            question?.answer === question?.radio_options?.add_value[0] &&
            question?.radio_options?.add_tosection === "17"
        )
    );
  } else {
  }

  useEffect(() => {
    if (entiresection.sectionId === "17") {
      const formTemplateToUpdate = cloneDeep(form_template);
      const sectionIndexToEdit = formTemplateToUpdate.findIndex(
        (section) => section.sectionId === "17"
      );

      formTemplateToUpdate[sectionIndexToEdit].questions = cloneDeep(
        formTemplateToUpdate
      )[sectionIndexToEdit].questions.filter((item) => item.question);
      const questionToAdd = cloneDeep(sectionDeficiency);
      Object.values(formTemplateToUpdate).forEach((sectionData) => {
        if (sectionData.questions) {
          sectionData.questions.forEach((question) => {
            if (
              (question.questionType === "Radio" &&
                question.answer === "Yes") ||
              question.answer === "Pass" ||
              (question.answer === "Safe" &&
                question.radio_options.add_tosection === "17" &&
                formTemplateToUpdate[sectionIndexToEdit].questions.some(
                  (item) => item.question === question.question
                ))
            ) {
              formTemplateToUpdate[sectionIndexToEdit].questions = cloneDeep(
                formTemplateToUpdate
              )[sectionIndexToEdit].questions.filter(
                (item) => item.question !== question.question
              );
            } else if (
              (question.questionType === "Radio" && question.answer === "No" && question.radio_options.add_tosection === "17") ||
              question.answer === "Fail" ||
              (question.answer === "Unsafe" &&
                question.radio_options.add_tosection === "17")
            ) {
              const existingQuestionIndex = formTemplateToUpdate[
                sectionIndexToEdit
              ].questions.findIndex(
                (item) => item.question === question.question
              );
              if (existingQuestionIndex === -1) {
                const descriptionModel = question.description_model || [];
                const newSubQuestions = questionToAdd.subQuestion.map(
                  (subquestion) => {
                    if (subquestion.question === "Describe The Deficiency") {
                      subquestion.answer =
                        question.description_model &&
                          question.description_model[0]
                          ? question.description_model[0].text
                          : subquestion.answer;
                    } else if (subquestion.question === "Status") {
                      subquestion.answer = "Open";
                    } else {
                      subquestion.multiple_picture = [];
                      subquestion.answer = "";
                    }
                    return { ...subquestion };
                  }
                );

                formTemplateToUpdate[sectionIndexToEdit].questions.push({
                  ...cloneDeep(questionToAdd),
                  question: question.question,
                  questionId: uuidv4(),
                });
              } else {
                const descriptionModel = question.description_model || [];
                const updatedSubQuestions = formTemplateToUpdate[
                  sectionIndexToEdit
                ].questions[existingQuestionIndex].subQuestion.map(
                  (subquestion) => {
                    if (subquestion.question === "Describe The Deficiency") {
                      subquestion.answer =
                        subquestion.answer === "" ||
                          (question.description_model &&
                            question.description_model[0] &&
                            subquestion.answer ===
                            question.description_model[0].text)
                          ? descriptionModel[0]
                            ? descriptionModel[0].text
                            : subquestion.answer
                          : subquestion.answer;
                    }
                    return { ...subquestion };
                  }
                );
                formTemplateToUpdate[sectionIndexToEdit].questions[
                  existingQuestionIndex
                ].answer = question.answer;
                formTemplateToUpdate[sectionIndexToEdit].questions[
                  existingQuestionIndex
                ].subQuestion = updatedSubQuestions;
              }
            } else if (
              question.questionType === "Radio" &&
              question.answer === "N/A" &&
              question.radio_options.add_tosection === "17" &&
              formTemplateToUpdate[sectionIndexToEdit].questions.some(
                (item) => item.question === question.question
              )
            ) {
              formTemplateToUpdate[sectionIndexToEdit].questions = cloneDeep(
                formTemplateToUpdate
              )[sectionIndexToEdit].questions.filter(
                (item) => item.question !== question.question
              );
            }
          });
        }
        setForm_template(formTemplateToUpdate);
      });
    }
  }, [getProceed]);

  useEffect(() => {
    if (
      entiresection.sectionName ===
      "Overall Impression of the Site Visit & Time Spent"
    ) {
      const formTemplateToUpdate = cloneDeep(form_template);
      const sectionIndexToEdit = formTemplateToUpdate.findIndex((section) =>
        section.questions.some((question) => question.question === "No Answers")
      );
      let NoquestionCount = 0;
      let YesquestionCount = 0;
      form_template.forEach((section) => {
        NoquestionCount += section.questions.filter(
          (question) =>
            question.questionType === "Radio" && question.answer === "No"
        ).length;
        YesquestionCount += section.questions.filter(
          (question) =>
            question.questionType === "Radio" && question.answer === "Yes"
        ).length;
      });
      if (sectionIndexToEdit !== -1) {
        const NoquestionIndex = formTemplateToUpdate[
          sectionIndexToEdit
        ].questions.findIndex((question) => question.question === "No Answers");
        formTemplateToUpdate[sectionIndexToEdit].questions[
          NoquestionIndex
        ].answer = NoquestionCount + "";

        const yesquestionIndex = formTemplateToUpdate[
          sectionIndexToEdit
        ].questions.findIndex(
          (question) => question.question === "Yes Answers"
        );
        formTemplateToUpdate[sectionIndexToEdit].questions[
          yesquestionIndex
        ].answer = YesquestionCount + "";
      }

      setForm_template(formTemplateToUpdate);
    }
  }, [getProceed]);

  useEffect(() => {
    if (!searchText) {
      setSelectedOptions([]);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      onChangeFormTemplate({
        sectionId: sectionId,
        answer: selectedOption.value,
        questionId: questions_obj.questionId,
        required: questions_obj.state,
      });

      getSearchOptions();
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const getSearchOptions = async () => {
    const response = await GetAutoSuggestionsform();
    setSelectedOptions((e) => {
      return response.map((option) => ({ label: option, value: option }));
    });
  };

  const handleDropdownValueChange = (SelectedOptionofDropdown) => {
    setUpdatedValueofDropdown(SelectedOptionofDropdown);
  };

  const handleDropdownfromApi = (SelectedOptionofDropdownApi) => {
    setUpdatedValueofDropdownApi(SelectedOptionofDropdownApi);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        300,
        300
      );
    });

  const wheather_radio = [
    { name: "°F", value: "imperial" },
    { name: "°C", value: "metric" },
  ];

  const getSignUrl = useCallback(() => {
    const sendDataImg = {
      files: [getSignBase64Uri],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID,
      setLoading: setLoading,
      setSetP: setSetP,
    };

    uploadMultiImage(setUploadedImgUrls, sendDataImg, setImgUrls);
  }, [getSignBase64Uri]);

  const getUploadImageUrlofImage = (fileObj) => {
    const sendDataImg = {
      files: [fileObj],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    uploadMultiImage(setUploadedImgUrlsofImages, sendDataImg, setImgUrls);
  };

  function getAddMoreDailyTaskFunc() {
    let _getAddMoreDailyTask = _.cloneDeep(getAddMoreDailyTask);

    let _form_template = _.cloneDeep(form_template);
    _form_template[4].questions[0].multipleQuestion.push({
      questions: [
        {
          description: "",
          question: "Task",
          questionType: "Description",
        },
        {
          description: "",
          question: "Hazards",
          questionType: "Description",
        },
        {
          description: "",
          question: "Mitigations",
          questionType: "Description",
        },
      ],
    });

    setForm_template(_form_template);
  }

  useEffect(() => {
    if (getImgAiDetectKeys.ai === "ai") {
      let category = questions_obj.category;

      detectAI(
        setAIData,
        getUploadedImgUrls12[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateDataforSpecificSection,
        setLoading,
        13
      );
    } else if (getImgAiDetectKeys.ai === "") {
      onChangeFormTemplate([
        13,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
      ]);
    } else if (getImgAiDetectKeys.ai === "pic") {
      onChangeFormTemplate([
        19,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "sign") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "signature",
      ]);
    } else if (getImgAiDetectKeys.ai === "subQuestionPic") {
      changeFormTemplateDataforSpecificSection([
        20,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "picNoSection") {
      changeFormTemplateData([
        21,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "PersonalFallArrestSystemMultiPic") {
      changeFormTemplateData([
        27,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "multiple_picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "ai-hazard-control") {
      let category = questions_obj.category;
      detectAI(
        setAIData,
        getUploadedImgUrls12[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateDataforSpecificSection,
        setLoading,
        25
      );
    } else if (getImgAiDetectKeys.ai === "ai-all") {
      aiALLDetectJson(getUploadedImgUrls12[0]);
    }
  }, [getUploadedImgUrls12, getImgAiDetectKeys, getImgMetaData]);

  const pageIndex = form_template.findIndex(
    (item) => item.sectionId === sectionId
  );
  const pageIndexofEmergency = form_template.findIndex(
    (item) => item.sectionName === "Means and Methods Hazards"
  );

  useEffect(() => {
    if (getState1) {
      let _getSelectedOptionsMulti1 = _.cloneDeep(getSelectedOptionsMulti1);
      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);

      form_template[pageIndexofEmergency].questions[0].table_options.map(
        (obj, i) => {
          if (obj.pre_selected_options.length) {
            let _optionsArr = obj.options.map((val) => {
              return { value: val, label: val, color: "#00B8D9" };
            });
            _getHCMOptions.push({
              value: obj.question,
              label: obj.question,
              color: "#00B8D9",
              isFixed: false,
              _options: _optionsArr,
              _s_key: i,
            });

            obj.selected_options.map((val, key) => {
              _getSelectedOptionsMulti1[i].push({
                value: val,
                label: val,
                color: "#00B8D9",
              });
            });
            _getCommentMulti1[i] = obj.comment_text;
          }
        }
      );

      setHCMOptions(_getHCMOptions);
      setSelectedOptionsMulti1(_getSelectedOptionsMulti1);
      setCommentMulti1(_getCommentMulti1);
    }
  }, [getState1]);

  const dailytaskQDel = (k, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndex].questions[value1].options.splice(k, 1);

    setForm_template(_form_template);
  };

  const dailytaskQAdd = (Value, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );

    _form_template[pageIndex].questions[value1].options.push("");

    setForm_template(_form_template);
  };

  let param = {
    filter: questions_obj.suggestionType,
    typed: searchText,
  };

  const GetAutoSuggestionsform = async () => {
    const response = await GetAutoSuggestions(param);
    return response;
  };

  useEffect(() => {
    async function GetDialogueTopicValues() {
      setLoadingtext(true);
      try {
        const response = await GetHazardTopic();
        const data = response;
        setdialoguetopicvalues(data);
        onChangeFormTemplate({
          sectionId: sectionId,
          answer: data.topic,
          questionId: questions_obj.questionId,
          required: questions_obj.state,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingtext(false);
      }
    }
    if (questions_obj.fileRequired === "Yes") {
      GetDialogueTopicValues();
    }
  }, [questions_obj.fileRequired]);

  useEffect(() => {
    if (getState1) {
      let _form_template = _.cloneDeep(form_template);
      let _getHCMOptions = getHCMOptions.map((obj) => obj.value);
      for (var i = 0; i < getHCMOptions.length; i++) {
        let _value = getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].length
          ? getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].map(
            (obj) => obj.value
          )
          : [];

        _form_template[pageIndexofEmergency].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].selected_options = _value;
        _form_template[pageIndexofEmergency].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].pre_selected_options = _value;
        _form_template[pageIndexofEmergency].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].comment_text = getCommentMulti1[getHCMOptions[i]["_s_key"]];
      }

      if (getHCMOptions.length !== 0) {
        for (
          let j = 0;
          j <
          _form_template[pageIndexofEmergency].questions[0].table_options
            .length;
          j++
        ) {
          if (
            _getHCMOptions.includes(
              _form_template[pageIndexofEmergency].questions[0].table_options[j]
                .question
            )
          ) {
            _form_template[pageIndexofEmergency].questions[0].table_options[
              j
            ].common = true;
          } else {
            _form_template[pageIndexofEmergency].questions[0].table_options[
              j
            ].common = false;
          }
        }
      } else {
        for (
          let j = 0;
          j <
          _form_template[pageIndexofEmergency].questions[0].table_options
            .length;
          j++
        ) {
          _form_template[pageIndexofEmergency].questions[0].table_options[
            j
          ].common = false;
        }
      }

      setForm_template(_form_template);
    }
  }, [getHCMOptions, getCommentMulti1, getSelectedOptionsMulti1]);

  const getUploadImageUrlSingle = (fileObj) => {
    const sendDataImg = {
      files: [fileObj],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    uploadMultiImage(setUploadedImgUrlsofImages, sendDataImg, setImgUrls);
  };
  const getUploadImageUrl = (fileObj) => {
    const sendDataImg = {
      files: [fileObj],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    uploadMultiImage(setUploadedImgUrls1, sendDataImg, setImgUrls);
  };

  const DescriptionQuestionDel = (k, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndex].questions[value1].options.splice(k, 1);
    setForm_template(_form_template);
  };

  const DescriptionQuestionAdd = (Value, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndex].questions[value1].options.push("");
    setForm_template(_form_template);
  };

  const DeficiencyDetailsQDel = (Question, index, deficient) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndexofDeficiency = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndexofDeficiency].questions.splice(index, 1);

    if (index === deficient.questions.length - 1) {
      _form_template[pageIndexofDeficiency].questions[0].repeatable = true;
    }
    setForm_template(_form_template);
  };

  const DeficiencyDetailsQAdd = (Questionu, value12) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndexofValue = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndexofValue].questions[value12].repeatable = false;
    _form_template[pageIndexofValue].questions[value12].visibility = true;
    _form_template[pageIndexofValue].questions[value12].questionId = uuidv4();
    _form_template[pageIndexofValue].questions.push(sectionCustomCorrective);
    setForm_template(_form_template);
  };

  const aiALLDetectJson = async (pic_url) => {
    const ai_all = await detectAIALL(pic_url, setLoading, setErrMsg);

    let _ai_all = [
      "gloves",
      "hard-hat",
      "harness",
      "safety-vest",
      "work-boots",
    ];
    Object.keys(ai_all.data).filter((obj_ai) => obj_ai !== "fire-ext");
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].section[getCrewLead].questions = _form_template[
      getProceed
    ].section[getCrewLead].questions.map((obj, i) => {
      _ai_all.map((obj_ai) => {
        if (obj.category === obj_ai) {
          if (ai_all.data[obj_ai]) {
            obj.answer = ai_all.data[obj_ai].status;
            obj.confidence = ai_all.data[obj_ai].confidence;
            obj.threshold = ai_all.data[obj_ai].threshold;
            obj.image_metadata = getImgMetaData;
            obj.picture = pic_url;
          } else {
            obj.answer = "";
            obj.confidence = "";
            obj.threshold = "";
            obj.picture = "";
          }
        }
      });
      return obj;
    });
    setForm_template(_form_template);
  };
  function handleSelect(data) {
    setSearchText(data);
  }
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    onChangeFormTemplate({
      sectionId: sectionId,
      answer: selectedOption.value,
      questionId: questions_obj.questionId,
      required: questions_obj.state,
    });
  };

  const ChangeOfDropdownValue = (DropdownOptions) => {
    setDropdownOptions(DropdownOptions);

    onChangeFormTemplate({
      sectionId: sectionId,
      Dropdown_answer: DropdownOptions.value,
      questionId: questions_obj.questionId,
      required: questions_obj.state,
    });
    DropdownOptions.value === "" && questions_obj.state === "Required"
      ? setError1("This field is required")
      : setError1("");
  };

  const getUploadSingleImage = async (
    _value,
    _key,
    _arr_key,
    fieldType,
    isAiImage = false,
    sectionsToUpdate = []
  ) => {
    let _getImgUrls = _.cloneDeep(getImgUrls);

    const sendDataImg = {
      files: [_value[0]],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };
    if (isAiImage) {
      const response = await checkImageData(
        {
          doc_file: _value[0],
        },
        setImgMetaData
      );

      if (fieldType === "fire-ext") {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        const indexToEdit = _form_template[getProceed].questions.findIndex(
          (question) => question.questionId === _value[2]
        );

        _form_template[getProceed].questions[indexToEdit] = {
          ..._form_template[getProceed].questions[indexToEdit],
          image_metadata: message,
        };
        setFieldType(message);
        setForm_template(_form_template);
      } else if (fieldType === "hard-hat" && _key.detectallppe === "Yes") {
        let Timeconversion = parseInt(response.time_diff);
        let message = "";

        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        setFieldType(message);
      } else {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        const indexToEdit = _form_template[getProceed].section[
          getCrewLead
        ].questions.findIndex((question) => question.questionId === _value[2]);
        _form_template[getProceed].section[getCrewLead].questions[indexToEdit] =
        {
          ..._form_template[getProceed].section[getCrewLead].questions[
          indexToEdit
          ],
          image_metadata: message,
        };
        setFieldType(message);
        setForm_template(_form_template);
      }
    }

    await uploadMultiImage(setUploadedImgUrls12, sendDataImg, setImgUrls);
    _value[0] !== "" &&
      setImgAiDetectKeys({
        section: _value[1],
        question12: _value[4],
        questions: _value[2],
        ai: _value[3],
      });
  };

  function getSecondPart(str) {
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    return str.split("-")[0];
  }

  const weatherIcon = (iconPhrase) => {
    if (iconPhrase !== null && iconPhrase === "clear") return ic_wind;
    else if (iconPhrase !== null && iconPhrase === "sunny") return ic_sunny;
    else if (
      (iconPhrase !== null && iconPhrase === "Mostly cloudy") ||
      iconPhrase === "Partly cloudy" ||
      iconPhrase === "Mostly sunny" ||
      iconPhrase === "Mostly clear"
    )
      return ic_partly_sunny;
    else if (iconPhrase !== null && iconPhrase === "rain") return ic_rain;
    else return ic_partly_sunny;
  };

  useEffect(() => {
    getSignBase64Uri !== "" && getSignUrl();
  }, [getSignUrl]);
  useEffect(() => {
    getUploadedImgUrls.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        signature: getUploadedImgUrls[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls]);
  useEffect(() => {
    getUploadedImgUrls1.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        multiple_picture: getUploadedImgUrls1[0],
        questionId: questions_obj.questionId,
        subQuestionId: questions_obj.subQuestion[5].questionId,
        updateSubQuestion: true,
      });
  }, [getUploadedImgUrls1]);

  useEffect(() => {
    getUploadedImgUrlsofImages.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        multiple_picture: getUploadedImgUrlsofImages[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrlsofImages]);
  useEffect(() => {
    getUploadedImgUrls12.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        picture: getUploadedImgUrls12[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls12]);
  const getDateObject = (strDate) => {
    let dateArr = strDate.split("/");
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  };

  const dailytaskQDelForward = (k) => {
    let _form_template = _.cloneDeep(form_template);
    const dropdownQuestionIndex = _form_template[
      getProceed
    ].questions.findIndex(
      (question) => question.questionType === "Description+Dropdown+Rearrange"
    );
    if (dropdownQuestionIndex !== -1) {
      _form_template[getProceed].questions[
        dropdownQuestionIndex
      ].options.splice(k, 1);
    }
    const multipleQuestionIndex = _form_template[
      getProceed + 1
    ].questions.findIndex(
      (question) => question.questionType === "Multiple-questions"
    );
    if (multipleQuestionIndex !== -1) {
      _form_template[getProceed + 1].questions[
        multipleQuestionIndex
      ].multipleQuestion.splice(k, 1);
    }
    setForm_template(_form_template);
  };

  const dailytaskQForward = (e) => {
    let _form_template = _.cloneDeep(form_template);
    const dropdownQuestionIndex = _form_template[
      getProceed
    ].questions.findIndex(
      (question) => question.questionType === "Description+Dropdown+Rearrange"
    );
    if (dropdownQuestionIndex !== -1) {
      _form_template[getProceed].questions[dropdownQuestionIndex].options.push(
        ""
      );
    }
    let _multipleQuestionClone = _.cloneDeep(
      _form_template[getProceed + 1].questions[0].multipleQuestion[0]
    );
    _multipleQuestionClone.questions[0].description = "";
    _form_template[getProceed + 1].questions[0].multipleQuestion.push(
      _multipleQuestionClone
    );
    setForm_template(_form_template);
  };

  const getDateObjectofSub = (strDate) => {
    let dateArr = strDate.split("/");
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  };
  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <div className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const modalAddCertificate = (event) => {
    let existingCertificateModel = [];

    const Options = getCertificateOptionListD
      .map((obj, i) => {
        if (!existingCertificateModel.includes(obj.certificateName)) {
          return {
            value: obj.certificateName,
            label: obj.certificateName,
            color: "#00B8D9",
            _key: obj.certificateID,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    let dataMdl = {
      heading: "Add Certificate",
      body: "",
      options: Options,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl1(dataMdl);
    setShowMdl1(true);
    setCertifySection(event);
  };

  function TextQuestion({
    questions_obj,
    dialoguetopicvalues,
    onChangeFormTemplate,
    sectionId,
    getError1,
  }) {
    const hasFile = dialoguetopicvalues.file !== "";
    return (
      <>
        <Form.Label>
          <span className="text-danger bold-font">
            {questions_obj.state === "Required" ? "*" : ""}
          </span>
          {questions_obj.question}
        </Form.Label>
        {questions_obj.tooltip_message !== "" &&
          questions_obj.tooltip_message !== undefined && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-disabled">
                  {questions_obj.tooltip_message}
                </Tooltip>
              }
            >
              <span className="icon-info">i</span>
            </OverlayTrigger>
          )}

        <div className="d-flex align-items-center">
          <Form.Control
            className="rounded-0"
            type="text"
            disabled={questions_obj.readonly === true ? true : false}
            value={dialoguetopicvalues.topic}
            required={questions_obj.state === "Required" ? true : false}
            onChange={(e) => {
              onChangeFormTemplate({
                sectionId: sectionId,
                answer: e.target.value,
                questionId: questions_obj.questionId,
                required: questions_obj.state,
              });
              e.target.value === "" && questions_obj.state === "Required"
                ? setError1("This field is required")
                : setError1("");
            }}
          />
          <br />
          {hasFile && (
            <div className="file-wrapper">
              <a
                className="buttonDownload pointer"
                onClick={() => window.open(dialoguetopicvalues.file, "_blank")}
              >
                Download
              </a>
            </div>
          )}
        </div>

        <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
      </>
    );
  }

  const return_control = useMemo(() => {
    switch (questions_obj.questionType) {
      case "Image+Certificate":
        return (
          questions_obj.visibility && (
            <>
              <div className="flex-shrink-1 bd-highlight certifyDiv mt-5">
                <p className="TextofCertify">Add Certificate</p>

                <a
                  className="certifyLink"
                  onClick={() => {
                    modalAddCertificate({
                      sectionId: sectionId,
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                  }}
                >
                  <img
                    alt=""
                    src={String(plus)}
                    width="16"
                    height="16"
                    className="certifyImg rounded-circle"
                    style={{ border: "1px solid #000", cursor: "pointer" }}
                  />
                </a>
              </div>
            </>
          )
        );

      case "Date":
        return (
          questions_obj.visibility && (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />
              <DatePicker
                className="rounded-0 form-control"
                placeholderText=""
                required={questions_obj.state === "Required" ? true : false}
                selected={getDateObject(questions_obj.answer)}
                onChange={(date) =>
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: getDataInFormat(date),
                    questionId: questions_obj.questionId,
                  })
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                todayButton="Today"
                calendarContainer={MyContainer}
              />
            </div>
          )
        );
      //////////////////////////////////////////

      //////////////////////////////////////////
      case "Image+Number":
        return (
          questions_obj.visibility && (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0 mt-3"
                type="number"
                value={questions_obj.answer}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <br />
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                            ImageUrl: obj,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          )
        );
      //////////////////////////////////////////

      case "Image":
        return (
          questions_obj.visibility &&
          (questions_obj.detectallppe === "Yes" ? (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>

              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Control
                className="rounded-0 custom-file-input"
                type="file"
                onChange={async (e) => {
                  // let fileSize = e.target.files[0]['size'];
                  let fileType = e.target.files[0]["type"];
                  let validImageTypes = [
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                  ];

                  const file = e.target.files[0];
                  const image = await resizeFile(file);
                  getUploadSingleImage(
                    [file, sectionId, questions_obj.questionId],
                    questions_obj,
                    "0",
                    questions_obj.category,
                    true
                  );
                  getUploadSingleImage(
                    [image, sectionId, questions_obj.questionId, "ai-all"],
                    questions_obj
                  );
                }}
              />

              {questions_obj.picture !== "" &&
                typeof questions_obj.picture !== "undefined" ? (
                <div className="p-1 m-1 imgDiv">
                  <div
                    className="removeImg"
                    onClick={() =>
                      changeFormTemplateDataforSpecificSection([
                        32,
                        questions_obj.category,
                        sectionId,
                        questions_obj.questionId,
                      ])
                    }
                  >
                    x
                  </div>
                  <img
                    src={
                      getUrlWithQueryString(questions_obj.picture) + IMG_TOKEN()
                    }
                    alt={
                      questions_obj.picName !== ""
                        ? questions_obj.picName
                        : null
                    }
                    className="shadow-sm bg-light rounded img-size_preview"
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Control
                className="rounded-0 custom-file-input"
                type="file"
                onChange={async (e) => {
                  // let fileSize = e.target.files[0]['size'];
                  let fileType = e.target.files[0]["type"];
                  let validImageTypes = [
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                  ];
                  const file = e.target.files[0];

                  getUploadSingleImage(
                    [file, sectionId, questions_obj.questionId],
                    null,
                    "0",
                    questions_obj.category,
                    false
                  );
                }}
              />
              {questions_obj.picture !== "" &&
                typeof questions_obj.picture !== "undefined" ? (
                <div className="p-1 m-1 imgDiv">
                  <div
                    className="removeImg"
                    onClick={() =>
                      removeImgData({
                        sectionId: sectionId,
                        questionId: questions_obj.questionId,
                        idx: 0,
                        ImageUrl: questions_obj.picture,
                      })
                    }
                  >
                    x
                  </div>
                  <img
                    src={
                      getUrlWithQueryString(questions_obj.picture) + IMG_TOKEN()
                    }
                    alt={
                      questions_obj.picName !== ""
                        ? questions_obj.picName
                        : null
                    }
                    className="shadow-sm bg-light rounded img-size_preview"
                  />
                </div>
              ) : null}

              <div></div>
            </div>
          ))
        );

      //////////////////////////////////////////

      // //////////////////////////////////////////

      case "Radio+Multiple-Image":
        return (
          questions_obj.visibility && (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />

              {questions_obj.methodType === "AI" ? (
                <div>
                  <div key="inline-radio" className="mb-3 mt-3">
                    <img
                      src={String(ic_ai)}
                      alt="ai-img"
                      className="me-2"
                      style={{ width: "32px" }}
                    />
                    <Form.Check
                      inline
                      label="Yes"
                      name="group9-0"
                      type="radio"
                      id="inline-radio-9-1"
                      checked={
                        questions_obj.overridden === "Yes"
                          ? questions_obj.overridden === "Yes"
                          : questions_obj.answer === "Yes"
                      }
                      onClick={() =>
                        changeFormTemplateDataforSpecificSection([
                          26,
                          "Yes",
                          sectionId,
                          questions_obj.questionId,
                        ])
                      }
                      disabled={questions_obj.confidence === ""}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="group9-1"
                      type="radio"
                      id="inline-radio-9-2"
                      checked={
                        questions_obj.overridden === "Yes"
                          ? questions_obj.overridden === "No"
                          : questions_obj.answer === "No"
                      }
                      onClick={() =>
                        changeFormTemplateDataforSpecificSection([
                          26,
                          "No",
                          sectionId,
                          questions_obj.questionId,
                        ])
                      }
                    />
                  </div>
                  <Form.Control
                    className="rounded-0 custom-file-input"
                    type="file"
                    onChange={async (e) => {
                      // let fileSize = e.target.files[0]['size'];
                      let fileType = e.target.files[0]["type"];
                      let validImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                      ];

                      const file = e.target.files[0];
                      const image = await resizeFile(file);
                      getUploadSingleImage(
                        [file, sectionId, questions_obj.questionId],
                        questions_obj,
                        "0",
                        questions_obj.category,
                        true
                      );
                      getUploadSingleImage(
                        [
                          image,
                          sectionId,
                          questions_obj.questionId,
                          "ai-hazard-control",
                        ],
                        questions_obj
                      );
                    }}
                  />

                  {getFieldType === "Time Verified"
                    ? questions_obj.confidence !== "" && (
                      <div className="mt-3 mb-1 text-success">
                        {getFieldType}
                      </div>
                    )
                    : questions_obj.confidence !== "" && (
                      <div className="mt-3 mb-1 text-warning">
                        {getFieldType}
                      </div>
                    )}

                  {questions_obj.picture !== "" &&
                    typeof questions_obj.picture !== "undefined" ? (
                    <div className="p-1 m-1 imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          changeFormTemplateDataforSpecificSection([
                            38,
                            questions_obj.category,
                            sectionId,
                            questions_obj.questionId,
                          ])
                        }
                      >
                        x
                      </div>
                      <img
                        src={
                          getUrlWithQueryString(questions_obj.picture) +
                          IMG_TOKEN()
                        }
                        alt={
                          questions_obj.picName !== ""
                            ? questions_obj.picName
                            : null
                        }
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </div>
                  ) : null}

                  {questions_obj.confidence !== "" && (
                    <div>
                      <div>Confidence: {questions_obj.confidence * 100}%</div>
                      <div>
                        <ProgressBar
                          variant="success"
                          now={questions_obj.confidence * 100}
                          label={`${questions_obj.confidence * 100}% `}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Form.Check
                    inline
                    label="Yes"
                    name={`group-${questions_obj.questionId} `}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${questions_obj.questionId}`}
                    checked={questions_obj.answer === "Yes"}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: "Yes",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name={`group-${questions_obj.questionId} `}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${questions_obj.questionId}`}
                    checked={questions_obj.answer === "No"}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: "No",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                  {questions_obj.pictureRequired && (
                    <Form.Control
                      className="rounded-0 custom-file-input mb-3"
                      type="file"
                      onChange={(e) => {
                        getUploadImageUrl(e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <div className="mb-3">
                      {"Uploaded Image Count:" +
                        questions_obj.multiple_picture.length}
                    </div>
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                      {questions_obj.multiple_picture.map((obj, i) => (
                        <Col className="item-w m-1  imgDiv">
                          <div
                            className="removeImg"
                            onClick={() =>
                              removeImgData({
                                sectionId: sectionId,
                                questionId: questions_obj.questionId,
                                idx: i,
                                ImageUrl: obj,
                              })
                            }
                          >
                            x
                          </div>
                          <img
                            src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                            alt={i}
                            className="shadow-sm bg-light rounded img-size_preview"
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              )}
            </div>
          )
        );
      //////////////////////////////////////

      case "Radio+Image":
        return (
          questions_obj.visibility && (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Control
                className="rounded-0 custom-file-input"
                type="file"
                onChange={async (e) => {
                  // let fileSize = e.target.files[0]['size'];
                  let fileType = e.target.files[0]["type"];
                  let validImageTypes = [
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                  ];

                  const file = e.target.files[0];
                  const image = await resizeFile(file);

                  getUploadSingleImage(
                    [file, sectionId, questions_obj.questionId],
                    questions_obj,
                    "0",
                    questions_obj.category,
                    true
                  );
                  getUploadSingleImage(
                    [image, sectionId, questions_obj.questionId, "ai"],
                    questions_obj
                  );
                }}
              />
              {questions_obj.image_metadata === "Time Verified"
                ? questions_obj.confidence !== "" && (
                  <div className="mt-3 mb-1 text-success">
                    {questions_obj.image_metadata}
                  </div>
                )
                : questions_obj.confidence !== "" && (
                  <div className="mt-3 mb-1 text-warning">
                    {questions_obj.image_metadata}
                  </div>
                )}

              {questions_obj.picture !== "" &&
                typeof questions_obj.picture !== "undefined" ? (
                <div className="p-1 m-1 imgDiv">
                  <div
                    className="removeImg"
                    onClick={() =>
                      changeFormTemplateDataforSpecificSection([
                        32,
                        questions_obj.category,
                        sectionId,
                        questions_obj.questionId,
                      ])
                    }
                  >
                    x
                  </div>
                  <img
                    src={
                      getUrlWithQueryString(questions_obj.picture) + IMG_TOKEN()
                    }
                    alt={
                      questions_obj.picName !== ""
                        ? questions_obj.picName
                        : null
                    }
                    className="shadow-sm bg-light rounded img-size_preview"
                  />
                </div>
              ) : null}

              <div key="inline-radio" className="mb-3 mt-3">
                <img
                  src={String(ic_ai)}
                  alt="ai-img"
                  className="me-2"
                  style={{ width: "32px" }}
                />
                <Form.Check
                  inline
                  label="Yes"
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${questions_obj.questionId}`}
                  checked={
                    questions_obj.overridden === "Yes"
                      ? questions_obj.overridden === "Yes"
                      : questions_obj.answer === "Yes"
                  }
                  onClick={() =>
                    changeFormTemplateDataforSpecificSection([
                      7,
                      "Yes",
                      sectionId,
                      questions_obj.questionId,
                    ])
                  }
                  disabled={questions_obj.confidence === ""}
                />
                <Form.Check
                  inline
                  label="No"
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${questions_obj.questionId}`}
                  checked={
                    questions_obj.overridden === "Yes"
                      ? questions_obj.overridden === "No"
                      : questions_obj.answer === "No"
                  }
                  onClick={() =>
                    changeFormTemplateDataforSpecificSection([
                      7,
                      "No",
                      sectionId,
                      questions_obj.questionId,
                    ])
                  }
                />
              </div>

              <div></div>
              {questions_obj.confidence !== "" && (
                <div>
                  <div>Confidence: {questions_obj.confidence * 100}%</div>
                  <div>
                    <ProgressBar
                      variant="success"
                      now={questions_obj.confidence * 100}
                      label={`${questions_obj.confidence * 100}% `}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        );

      /////////////////////////////////////////////////

      /////////////////////////////////////////////////

      case "Image+Text":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group
                    controlId={`form-10- ${questions_obj.questionId}`}
                    className="mb-1"
                  >
                    <Form.Control
                      className="rounded-0"
                      type="text"
                      value={questions_obj.answer}
                      onChange={(e) =>
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: e.target.value,
                          questionId: questions_obj.questionId,
                        })
                      }
                    />
                    <br />
                    <Form.Control
                      className="rounded-0 custom-file-input"
                      type="file"
                      onChange={async (e) => {
                        // let fileSize = e.target.files[0]['size'];
                        let fileType = e.target.files[0]["type"];
                        let validImageTypes = [
                          "image/gif",
                          "image/jpeg",
                          "image/png",
                        ];
                        const file = e.target.files[0];
                        const image = await resizeFile(file);
                        getUploadSingleImage([
                          image,
                          sectionId,
                          questions_obj.questionId,
                        ]);
                      }}
                    />
                    {questions_obj.picture !== "" &&
                      typeof questions_obj.picture !== "undefined" ? (
                      <div className="p-1 m-1 imgDiv">
                        <div
                          className="removeImg"
                          onClick={() =>
                            removeImgData({
                              sectionId: sectionId,
                              questionId: questions_obj.questionId,
                              idx: 0,
                              ImageUrl: questions_obj.picture,
                            })
                          }
                        >
                          x
                        </div>
                        <img
                          src={
                            getUrlWithQueryString(questions_obj.picture) +
                            IMG_TOKEN()
                          }
                          alt={
                            questions_obj.picName !== ""
                              ? questions_obj.picName
                              : null
                          }
                          className="shadow-sm bg-light rounded img-size_preview"
                        />
                      </div>
                    ) : null}

                    {entiresection.section !== undefined &&
                      <>
                        {entiresection.section[getCrewLead].questions.findIndex(
                          (question) => question.questionType === "Image+Text"
                        ) ===
                          entiresection.section[getCrewLead].questions.length - 1 ? (
                          <div className="mb-4">
                            <Button
                              size="sm"
                              className="mt-3"
                            // onClick={addMorePPEOther}
                            >
                              + Add More
                            </Button>
                          </div>
                        ) : null}
                      </>
                    }
                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );

      //////////////////////////////////

      //////////////////////////////////
      case "Sub-question":
        if (questions_obj.visibility) {
          return (
            <div>
              {entiresection.questions.length > 1 &&
                questions_obj.hasOwnProperty("repeatable") ? (
                <div className="d-flex flex-row justify-content-end ">
                  <img
                    className="icon-red-color pointer "
                    onClick={() =>
                      DeficiencyDetailsQDel(
                        questions_obj,
                        questions_obj_key,
                        entiresection
                      )
                    }
                    src={String(trashcan)}
                    alt="delete icon"
                  />
                </div>
              ) : null}
              <Row xs={12}>
                <Col>
                  {questions_obj.question != "" ? (
                    <Form.Label>
                      <span className="text-danger bold-font">
                        {questions_obj.state === "Required" ? "*" : ""}
                      </span>
                      {questions_obj.question}
                    </Form.Label>
                  ) : null}
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}

                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.subQuestion.map((s_obj, k) => {
                        if (s_obj.visibility) {
                          switch (s_obj.questionType) {
                            case "Description":
                              return (
                                <>
                                  <Form.Label>
                                    <span className="text-danger bold-font">
                                      {s_obj.state === "Required" ? "*" : ""}
                                    </span>
                                    {s_obj.question}
                                  </Form.Label>
                                  <Form.Control
                                    key={k}
                                    className="rounded-0"
                                    as="textarea"
                                    rows={5}
                                    id={"form-3-2-" + k}
                                    value={s_obj.answer}
                                    placeholder={
                                      s_obj.hasOwnProperty("placeholder")
                                        ? s_obj.placeholder
                                        : "Please Enter " + s_obj?.question
                                    }
                                    required={
                                      s_obj.state === "Required" ? true : false
                                    }
                                    onChange={(e) => {
                                      onChangeFormTemplate({
                                        sectionId: sectionId,
                                        answer: e.target.value,
                                        questionId: questions_obj.questionId,
                                        subQuestionId: s_obj.questionId,
                                        updateSubQuestion: true,
                                      });
                                    }}
                                  />

                                  <br />
                                </>
                              );
                            case "Text":
                              return (
                                <>
                                  {s_obj.emailValidation === "Yes" ? (
                                    <>
                                      <Form.Label>
                                        <span className="text-danger bold-font">
                                          {s_obj.state === "Required" ? "*" : ""}
                                        </span>
                                        {s_obj.question}
                                      </Form.Label>
                                      <Form.Control
                                        placeholder={
                                          s_obj.hasOwnProperty("placeholder")
                                            ? s_obj?.placeholder
                                            : "Please Enter email ID"
                                        }
                                        {...register("OwnerOfDeficiency")}
                                        value={s_obj.answer}
                                        required={
                                          s_obj.state === "Required"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          onChangeFormTemplate({
                                            sectionId: sectionId,
                                            answer: e.target.value,
                                            questionId: questions_obj.questionId,
                                            subQuestionId: s_obj.questionId,
                                            updateSubQuestion: true,
                                          });

                                          if (emailValidation(e)) {

                                            setError("OwnerOfDeficiency", {
                                              message:
                                                "Please enter a valid email address",
                                            });
                                          } else {

                                            setError("OwnerOfDeficiency", {
                                              message: "",
                                            });

                                            if (
                                              e.target.value
                                                .split("@")[1]
                                                .split(".").length === 2
                                            ) {

                                              setError("OwnerOfDeficiency", {
                                                message: "",
                                              });
                                            } else {

                                              setError("OwnerOfDeficiency", {
                                                message:
                                                  "Please enter a valid email address",
                                              });
                                            }
                                          }
                                        }}
                                      />

                                      <Form.Label className="mt-2 text-danger">
                                        {errors.OwnerOfDeficiency &&
                                          errors.OwnerOfDeficiency.message}
                                      </Form.Label>
                                    </>
                                  ) : (
                                    <>
                                      <Form.Label>
                                        <span className="text-danger bold-font">
                                          {s_obj.state === "Required" ? "*" : ""}
                                        </span>
                                        {s_obj.question}
                                      </Form.Label>
                                      {s_obj.tooltip_message !== "" &&
                                        s_obj.tooltip_message !== undefined && (
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip-disabled">
                                                {s_obj.tooltip_message}
                                              </Tooltip>
                                            }
                                          >
                                            <span className="icon-info">i</span>
                                          </OverlayTrigger>
                                        )}
                                      <Form.Control
                                        className="rounded-0"
                                        type="text"
                                        {...register("textBox" + k)}
                                        value={s_obj.answer}
                                        required={s_obj.state === "Required" ? true : false}
                                        placeholder={
                                          s_obj.hasOwnProperty("placeholder")
                                            ? s_obj?.placeholder
                                            : "Please Enter " + s_obj?.question
                                        }
                                        onChange={(e) => {
                                          onChangeFormTemplate({
                                            sectionId: sectionId,
                                            answer: e.target.value,
                                            questionId: questions_obj.questionId,
                                            subQuestionId: s_obj.questionId,
                                            updateSubQuestion: true,
                                          });
                                          e.target.value === "" && s_obj.state === "Required"
                                            ? setError("textBox" + k, { message: "This field is required", })
                                            : setError("textBox" + k, { message: "", })
                                        }}
                                      />

                                      <Form.Label className="mt-2 text-danger">{errors["textBox" + k] &&
                                        errors["textBox" + k].message}</Form.Label>
                                    </>
                                  )}
                                  <br />
                                </>
                              );

                            case "Dropdown":
                              return (
                                <>
                                  {s_obj.apiDependentDropdown ?
                                    <>
                                      {s_obj.userFilterSuggestion === "supplier" ?
                                        <DropdownForSubQuestion
                                          handleDropdownValueChange={handleDropdownValueChange}
                                          SelectedOptionofDropdown={SelectedOptionofDropdown}
                                          setSelectedOptionofDropdown={setSelectedOptionofDropdown}
                                          emailError={emailError}
                                          setEmailError={setEmailError}
                                          s_obj={s_obj}
                                          questions_obj={questions_obj}
                                          onChangeFormTemplate={onChangeFormTemplate}
                                          sectionId={sectionId}
                                          getEmployeeDropdownval={getEmployeeDropdownval}
                                        />
                                        :
                                        <>
                                          <DropdownForEmployeeSubQuestion
                                            s_obj={s_obj}
                                            questions_obj={questions_obj}
                                            onChangeEmployee={onChangeEmployee}
                                            sectionId={sectionId}
                                            getEmployeeDropdownval={getEmployeeDropdownval}
                                            SelectedOptionofDropdown={SelectedOptionofDropdown}
                                            getApiResponseEmployee={getApiResponseEmployee}
                                          />
                                        </>
                                      }
                                    </>
                                    : (
                                      <>
                                        <Form.Label>
                                          <span className="text-danger bold-font">
                                            {s_obj.state === "Required" ? "*" : ""}
                                          </span>
                                          {s_obj.question}
                                        </Form.Label>
                                        <Form.Select
                                          aria-label={`select-${s_obj.questionId}`}
                                          className="rounded-0"
                                          defaultValue={s_obj.answer}
                                          required={
                                            s_obj.state === "Required"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            onChangeFormTemplate({
                                              sectionId: sectionId,
                                              answer: e.target.value,
                                              questionId: questions_obj.questionId,
                                              subQuestionId: s_obj.questionId,
                                              updateSubQuestion: true,
                                            });
                                            e.target.value === "" &&
                                              s_obj.state === "Required"
                                              ? setError1("This field is required")
                                              : setError1("");
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {s_obj.options.map((obj) => (
                                            <option value={obj}>{obj}</option>
                                          ))}
                                        </Form.Select>
                                        <Form.Label className="mt-2 text-danger">
                                          {getError1}
                                        </Form.Label>
                                      </>
                                    )}
                                </>
                              );
                            case "Date":
                              return (
                                <>
                                  <div>
                                    <Form.Label>
                                      <span className="text-danger bold-font">
                                        {s_obj.state === "Required" ? "*" : ""}
                                      </span>
                                      {s_obj.question}
                                    </Form.Label>
                                    <br />

                                    <DatePicker
                                      className="rounded-0 form-control"
                                      placeholderText={
                                        s_obj.hasOwnProperty("placeholder")
                                          ? s_obj?.placeholder
                                          : "Please select " + s_obj?.question
                                      }
                                      required={
                                        s_obj.state === "Required" ? true : false
                                      }
                                      value={s_obj.answer}
                                      onChange={(date) => {
                                        onChangeFormTemplate({
                                          sectionId: sectionId,
                                          answer: getDataInFormat(date),
                                          questionId: questions_obj.questionId,
                                          subQuestionId: s_obj.questionId,
                                          updateSubQuestion: true,
                                        });
                                        // questions_obj.subQuestion[3].answer === '' && questions_obj.subQuestion[3].state === "Required" ? setError1("This field is required") : setError1("");
                                      }}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      todayButton="Today"
                                      calendarContainer={MyContainer}
                                    />
                                  </div>

                                  <br />
                                </>
                              );

                            case "Image":
                              return (
                                <>
                                  <div>
                                    <Form.Label>
                                      <span className="text-danger bold-font">
                                        {s_obj.state === "Required" ? "*" : ""}
                                      </span>
                                      {s_obj.question}
                                    </Form.Label>
                                    <>
                                      {
                                        <Form.Control
                                          className="rounded-0 custom-file-input mb-3"
                                          type="file"
                                          onChange={(e) => {
                                            getUploadImageUrl(e.target.files[0]);
                                            e.target.value = null;
                                          }}
                                        />
                                      }
                                      {s_obj.multiple_picture.length !== 0 && (
                                        <div className="mb-3">
                                          {"Uploaded Image Count:" +
                                            s_obj.multiple_picture.length}
                                        </div>
                                      )}
                                      {s_obj.multiple_picture.length !== 0 && (
                                        <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                                          {s_obj.multiple_picture.map(
                                            (obj, i) => (
                                              <Col className="item-w m-1  imgDiv">
                                                <img
                                                  src={
                                                    getUrlWithQueryString(obj) +
                                                    IMG_TOKEN()
                                                  }
                                                  alt={i}
                                                  className="shadow-sm bg-light rounded img-size_preview"
                                                />
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                      )}
                                    </>
                                  </div>

                                  <br />
                                </>
                              );

                            ///////////////////////////////////////
                            case "Multiple-Image":
                              return (
                                <>
                                  <div>
                                    <Form.Label>
                                      <span className="text-danger bold-font">
                                        {s_obj.state === "Required" ? "*" : ""}
                                      </span>
                                      {s_obj.question}
                                    </Form.Label>
                                    <>
                                      {
                                        <Form.Control
                                          className="rounded-0 custom-file-input mb-3"
                                          type="file"
                                          onChange={async (e) => {
                                            const file = e.target.files[0];
                                            if (
                                              s_obj.multiple_picture.length > 4
                                            ) {
                                              alert(
                                                "You can only upload upto 5 Images"
                                              );
                                              return;
                                            }
                                            if (file.size >= 5e6) {
                                              const image = await resizeFile(
                                                file
                                              );
                                              getUploadImageUrl(image);
                                              e.target.value = null;
                                            } else {
                                              getUploadImageUrl(file);
                                              e.target.value = null;
                                            }
                                          }}
                                        />
                                      }
                                      {s_obj.multiple_picture.length !== 0 && (
                                        <div className="mb-3">
                                          {"Uploaded Image Count:" +
                                            s_obj.multiple_picture.length}
                                        </div>
                                      )}
                                      {s_obj.multiple_picture.length !== 0 && (
                                        <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                                          {s_obj.multiple_picture.map(
                                            (obj, i) => (
                                              <Col className="item-w m-1  imgDiv">
                                                <div className="removeImg"
                                                onClick={() =>
                                                  removeImgData({
                                                    sectionId: sectionId,
                                                    questionId:
                                                      questions_obj.questionId,
                                                    idx: i,
                                                    ImageUrl: obj,
                                                  })
                                                }
                                              >
                                                x
                                              </div>
                                                <img
                                                  src={
                                                    getUrlWithQueryString(obj) +
                                                    IMG_TOKEN()
                                                  }
                                                  alt={i}
                                                  className="shadow-sm bg-light rounded img-size_preview"
                                                />
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                      )}
                                    </>
                                  </div>

                                  <br />
                                </>
                              );
                            ///////////////////////////////////////
                            default:
                              return (
                                <>
                                  <Form.Label>
                                    <span className="text-danger bold-font">
                                      {questions_obj.state === "Required"
                                        ? "*"
                                        : ""}
                                    </span>
                                    {questions_obj.question}
                                  </Form.Label>
                                  {questions_obj.tooltip_message !== "" &&
                                    questions_obj.tooltip_message !==
                                    undefined && (
                                      <OverlayTrigger
                                        placement="right"
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {questions_obj.tooltip_message}
                                          </Tooltip>
                                        }
                                      >
                                        <span className="icon-info">i</span>
                                      </OverlayTrigger>
                                    )}
                                  <Form.Control
                                    className="rounded-0"
                                    type="text"
                                    value={questions_obj.answer}
                                    // {...register('piNumber', { required: true })}
                                    placeholder={
                                      s_obj.hasOwnProperty("placeholder")
                                        ? questions_obj?.placeholder
                                        : "Please Enter" + questions_obj?.question
                                    }
                                    required={
                                      questions_obj.state === "Required"
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      onChangeFormTemplate({
                                        sectionId: sectionId,
                                        answer: e.target.value,
                                        questionId: questions_obj.questionId,
                                        required: questions_obj.state,
                                      });
                                      e.target.value === "" &&
                                        questions_obj.state === "Required"
                                        ? setError1("This field is required")
                                        : setError1("");
                                    }}
                                  />
                                  <Form.Label className="mt-2 text-danger">
                                    {getError1}
                                  </Form.Label>
                                </>
                              );
                          }
                        }
                      })}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );
        }

      /////////////////////////////
      case "Radio+Weather":
        setWeatherStatus(true);

        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Group controlId="form-6-1" className="mb-3">
                    <Form.Label>
                      <span className="text-danger bold-font">
                        {questions_obj.state === "Required" ? "*" : ""}
                      </span>
                      {questions_obj.question}
                    </Form.Label>
                    <div key="inline-radio" className="mb-3">
                      <Form.Check
                        inline
                        label="Yes"
                        name={`group-${questions_obj.questionId}`}
                        type="radio"
                        className="mb-3"
                        id="inline-radio-2"
                        checked={questions_obj.answer === "Yes"}
                        required={questions_obj.state === "Required" ? true : false}
                        onClick={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: "Yes",
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name={`group - ${questions_obj.questionId} `}
                        type="radio"
                        className="mb-3"
                        id="inline-radio-2"
                        checked={questions_obj.answer === "No"}
                        required={questions_obj.state === "Required" ? true : false}
                        onClick={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: "No",
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <ButtonGroup>
                        {wheather_radio.map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={idx % 2 ? "outline-danger" : "outline-danger"}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                            className="ps-5 pe-5"
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>

                    <>
                      <div
                        className="wrapper-w shadow p-2 m-2  rounded mt-3"
                        style={{ background: "lavender" }}
                      >
                        {!_.isEmpty(getWeatherHours) &&
                          getWeatherHours.forecasts.map((obj, k) => (
                            <Col
                              key={k}
                              lg={1}
                              md={1}
                              className=" shadow p-1 m-1 bg-white rounded bd-highlight item-w"
                            >
                              <div className="p-2 bd-highlight d-flex  justify-content-center">
                                {formatAMPM(obj.date)}
                              </div>
                              <div className="p-2 bd-highlight d-flex  justify-content-center">
                                <img
                                  style={{ width: "3em" }}
                                  className=""
                                  src={String(weatherIcon(obj.iconPhrase))}
                                  alt={obj.iconPhrase}
                                />
                              </div>
                              <div className="p-1 bd-highlight d-flex  justify-content-center">
                                {obj.temperature.value}{" "}
                                {wheather_radio[0].value === radioValue
                                  ? wheather_radio[0].name
                                  : wheather_radio[1].name}
                              </div>
                            </Col>
                          ))}
                      </div>

                      <Row className="mt-4">
                        <Col>
                          <div>
                            {questions_obj.weather_imperial.hasOwnProperty(
                              "weather_settings"
                            ) &&
                              !_.isEmpty(
                                questions_obj.weather_imperial.weather_settings
                              ) ? (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_temperature)}
                                  alt="img temperature"
                                  style={{
                                    width: "64px",
                                    filter: getTemperatureColorFilter(
                                      convertTemperature(
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.min,
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      convertTemperature(
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.max,
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      questions_obj.weather_imperial.results[
                                        getProceed12
                                      ].temperature.value,
                                      setwarningmessagerequired,
                                      warningmessagerequired
                                    ),
                                  }}
                                />
                                {warningmessagerequired.toastErrMsg ? (
                                  <div className="toast_message">
                                    <ToastMessage
                                      value={warningmessagerequired}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_temperature)}
                                  alt="img temperature"
                                  style={{ width: "64px" }}
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-center">
                              Temperature:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .temperature.value
                              }{" "}
                              {wheather_radio[0].value === radioValue
                                ? wheather_radio[0].name
                                : wheather_radio[1].name}
                              {questions_obj.weather_imperial.hasOwnProperty(
                                "weather_settings"
                              ) &&
                                !_.isEmpty(
                                  questions_obj.weather_imperial.weather_settings
                                ) ? (
                                <div className="d-flex justify-content-center ">
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-disabled"
                                        className="text_info"
                                      >
                                        Max:{" "}
                                        {convertTemperature(
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.max,
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {wheather_radio[0].value === radioValue
                                          ? wheather_radio[0].name
                                          : wheather_radio[1].name}{" "}
                                        and Min:{" "}
                                        {convertTemperature(
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.min,
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {wheather_radio[0].value === radioValue
                                          ? wheather_radio[0].name
                                          : wheather_radio[1].name}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={String(info)}
                                      className="icon-info"
                                    />
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                            </div>
                            <div className="d-flex justify-content-center" style={{ fontSize: "13px" }}>
                              Feel:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .realFeelTemperature.value
                              }
                              {wheather_radio[0].value === radioValue
                                ? wheather_radio[0].name
                                : wheather_radio[1].name}{" "}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            {questions_obj.weather_imperial.hasOwnProperty(
                              "weather_settings"
                            ) &&
                              !_.isEmpty(
                                questions_obj.weather_imperial.weather_settings
                              ) ? (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_wind)}
                                  alt="img wind"
                                  style={{
                                    width: "64px",
                                    filter: getWindGustColorFilter(
                                      convertMilesPerHour(
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.min,
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      convertMilesPerHour(
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.max,
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      questions_obj.weather_imperial.results[
                                        getProceed12
                                      ].wind.speed.value,
                                      setwarningmessagerequired,
                                      warningmessagerequired
                                    ),
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_wind)}
                                  alt="img wind"
                                  style={{ width: "64px" }}
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-center">
                              Wind:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .wind.speed.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .wind.speed.unit
                              }{" "}
                              {questions_obj.weather_imperial.hasOwnProperty(
                                "weather_settings"
                              ) &&
                                !_.isEmpty(
                                  questions_obj.weather_imperial.weather_settings
                                ) ? (
                                <div className="d-flex justify-content-center">
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-disabled"
                                        className="text_info"
                                      >
                                        Max:{" "}
                                        {convertMilesPerHour(
                                          questions_obj.weather_imperial
                                            .weather_settings.wind_gust.max,
                                          questions_obj.weather_imperial
                                            .weather_settings.wind_gust.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {
                                          questions_obj.weather_imperial
                                            .results[getProceed12].windGust
                                            .speed.unit
                                        }{" "}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={String(info)}
                                      className="icon-info"
                                    />
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="d-flex justify-content-center"
                              style={{ fontSize: "13px" }}
                            >
                              Gust:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .windGust.speed.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .windGust.speed.unit
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_sky)}
                                alt="img sky"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Cloud Cover:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .cloudCover
                              }
                              %
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_visibility)}
                                alt="img visibility"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              Visibility:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .visibility.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .visibility.unit
                              }
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_humidity)}
                                alt="img humidity"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Humidity:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .relativeHumidity
                              }
                              %
                            </div>
                            <div className="d-flex justify-content-center" style={{ fontSize: "13px" }}>
                              Dew Point:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .dewPoint.value
                              }{" "}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_pressure)}
                                alt="img pressure"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              Pressure:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .pressure.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .pressure.unit
                              }{" "}
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_precipitation)}
                                alt="img precipitation"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Precipitation: 0%
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_uv_index)}
                                alt="img uv index"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              UV Index:{" "}
                              {
                                questions_obj.weather_imperial.results[getProceed12]
                                  .uvIndex
                              }{" "}
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>
                    </>

                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );
      //////////////////////////////////////////////////
      case "Description":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                placeholder="Please enter..."
                value={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////
      case "Radio+Description":
        return (
          questions_obj.visibility &&
          (questions_obj.hasOwnProperty("radio_options") ? (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />

              {questions_obj.radio_options.values.map((obj_v, i) => {
                return (
                  <Form.Check
                    inline
                    label={obj_v}
                    name={`group-${questions_obj.questionId}`}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${i}-${questions_obj.questionId}`}
                    defaultChecked={questions_obj.answer === obj_v}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: obj_v,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                );
              })}

              {questions_obj.hasOwnProperty("description_model")
                ? questions_obj.description_model[0].visible && (
                  <Form.Control
                    className="rounded-0 mb-3"
                    as="textarea"
                    rows={5}
                    value={questions_obj.description_model[0].text}
                    onChange={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        desc_text: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                )
                : null}
            </div>
          ) : (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Check
                inline
                label="No"
                name={`group-${questions_obj.questionId}`}
                type="radio"
                className="mb-3"
                id={`inline-radio-${questions_obj.questionId} `}
                defaultChecked={questions_obj.answer === "No"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "No",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
              <Form.Check
                inline
                label="Yes"
                name={`group-${questions_obj.questionId}`}
                type="radio"
                className="mb-3"
                id={`inline-radio-${questions_obj.questionId}`}
                defaultChecked={questions_obj.answer === "Yes"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "Yes",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                placeholder={
                  questions_obj.hasOwnProperty("placeholder")
                    ? questions_obj.placeholder
                    : null
                }
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          ))
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Radio+Signature":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Check
                inline
                label="No"
                name={`group-${questions_obj.questionId}`}
                type="radio"
                className="mb-3"
                id={`inline-radio-${questions_obj.questionId}`}
                defaultChecked={questions_obj.answer === "No"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "No",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
              <Form.Check
                inline
                label="Yes"
                name={`group-${questions_obj.questionId}`}
                type="radio"
                className="mb-3"
                id={`inline-radio-${questions_obj.questionId}`}
                defaultChecked={questions_obj.answer === "Yes"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "Yes",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />

              <div className="mt-3 mb-1 text-warning">
                Please upload a self portrait and sign in the grey area
              </div>
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrlSingle(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                            ImageUrl: obj,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}

              {questions_obj.multiple_picture.length !== 0 ? (
                <div className="col-3">
                  <Signature
                    setSignBase64Uri={setSignBase64Uri}
                    imgName="signature.png"
                    resetSign={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        signature: "",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />

                  {questions_obj.signature !== "" && (
                    <div className="p-1 m-1">
                      <img
                        src={
                          getUrlWithQueryString(questions_obj.signature) +
                          IMG_TOKEN()
                        }
                        alt={
                          questions_obj.picName !== ""
                            ? questions_obj.picName
                            : null
                        }
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )
        );

      //////////////////////////////////////////////////
      //////////////////////////////////////////////////
      case "Description+VoicetoText":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={removeTags(questions_obj.answer)}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
            </>
          )
        );

      case "Description+Dropdown+Rearrange":
        return (
          questions_obj.visibility &&
          (questions_obj.validateCrewMemberEmail === "Y" ? (
            <div>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.options.map((obj, k) => {
                        return (
                          <div key={k} className="dailytaskQDivMain">
                            <div className="dailytaskQDiv" key={k}>
                              <Form.Control
                                id={"form-3-2-" + k}
                                placeholder={
                                  questions_obj.hasOwnProperty("placeholder")
                                    ? questions_obj.placeholder
                                    : ""
                                }
                                className="rounded-0"
                                type="text"
                                value={obj}
                                onChange={(e) => {
                                  changeFormTemplateDataforSpecificSection([
                                    36,
                                    e.target.value,
                                    questions_obj_key,
                                    k,
                                    sectionId,
                                  ]);
                                  if (emailValidation(e)) {

                                    setError("emailId" + k, {
                                      message:
                                        "Please enter a valid email address",
                                    });
                                  } else {

                                    setError("emailId" + k, { message: "" });

                                    if (
                                      e.target.value.split("@")[1].split(".")
                                        .length === 2
                                    ) {

                                      setError("emailId" + k, {
                                        message: "",
                                      });
                                    } else {

                                      setError("emailId" + k, {
                                        message:
                                          "Please enter a valid email address",
                                      });
                                    }
                                  }
                                }}
                              />
                              {questions_obj.options.length !== 1 ? (
                                <div className="d-flex dailytaskQDel">
                                  <img
                                    className="icon-red-color pointer"
                                    onClick={() =>
                                      dailytaskQDel(k, questions_obj_key)
                                    }
                                    src={String(trashcan)}
                                    alt="delete icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                            <Form.Label className="mt-2 text-danger">
                              {errors["emailId" + k] &&
                                errors["emailId" + k].message}
                            </Form.Label>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center m-3">
                        <Button
                          className=" bd-highlight"
                          size="sm"
                          onClick={(e) =>
                            dailytaskQAdd(entiresection, questions_obj_key)
                          }
                        >
                          + Add More
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : questions_obj.forwarsDailyTaskQstns ? (
            <div>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.options.map((obj, k) => {
                        return (
                          <div key={k} className="dailytaskQDivMain">
                            <div className="dailytaskQDiv" key={k}>
                              <Form.Control
                                id={"form-3-2-" + k}
                                placeholder="Please add your task."
                                className="rounded-0"
                                type="text"
                                value={obj}
                                onChange={(e) => {
                                  changeFormTemplateDataforSpecificSection([
                                    42,
                                    e.target.value,
                                    questions_obj_key,
                                    k,
                                  ]);
                                }}
                              />
                              {questions_obj.options.length !== 1 ? (
                                <div className="d-flex dailytaskQDel">
                                  <img
                                    className="icon-red-color pointer"
                                    onClick={() => dailytaskQDelForward(k)}
                                    src={String(trashcan)}
                                    alt="delete icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                            <Form.Label className="text-danger">
                              {obj === "" && "This field is required"}
                            </Form.Label>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center m-3">
                        <Button
                          className=" bd-highlight"
                          size="sm"
                          onClick={(e) => dailytaskQForward(e)}
                        >
                          + Add More
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.options.map((obj, k) => {
                        return (
                          <div key={k} className="dailytaskQDivMain">
                            <div className="dailytaskQDiv" key={k}>
                              <Form.Control
                                id={"form-3-2-" + k}
                                placeholder={
                                  questions_obj.hasOwnProperty("placeholder")
                                    ? questions_obj.placeholder
                                    : null
                                }
                                className="rounded-0"
                                type="text"
                                value={obj}
                                onChange={(e) => {
                                  changeFormTemplateDataforSpecificSection([
                                    55,
                                    e.target.value,
                                    questions_obj_key,
                                    k,
                                    sectionId,
                                  ]);
                                }}
                              />
                              {questions_obj.options.length !== 1 ? (
                                <div className="d-flex dailytaskQDel">
                                  <img
                                    className="icon-red-color pointer"
                                    onClick={() =>
                                      DescriptionQuestionDel(
                                        k,
                                        questions_obj_key
                                      )
                                    }
                                    src={String(trashcan)}
                                    alt="delete icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                            <Form.Label className="text-danger">
                              {obj === "" && "This field is required"}
                            </Form.Label>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center m-3">
                        <Button
                          className=" bd-highlight"
                          size="sm"
                          onClick={(e) =>
                            DescriptionQuestionAdd(
                              entiresection,
                              questions_obj_key
                            )
                          }
                        >
                          + Add More
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))
        );
      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Text+VoicetoText":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={removeTags(questions_obj.answer)}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////

      case "Description+Hospital":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>

              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="form-4-2" className="mb-3">
                <div className="position-relative ">
                  <div className="position-absolute top-0 end-0 sig-controls me-3">
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() =>
                        modalMapHandler1(
                          questions_obj.question,
                          getAddress,
                          changeFormTemplateData,
                          sectionId,
                          questions_obj.questionId,
                          questions_obj.lat,
                          questions_obj.long
                        )
                      }
                    >
                      <img
                        src={String(ic_trauma_center)}
                        alt="reset"
                        style={{ width: "18px" }}
                      />
                    </Button>
                  </div>
                  <Form.Control
                    className="rounded-0"
                    as="textarea"
                    rows={5}
                    value={removeTags(questions_obj.answer)}
                    onChange={(e) => {
                      e.target.value === ""
                        ? setError1("hospitalInfo", {
                          message: "This field is required",
                        })
                        : setError1("hospitalInfo", { message: "" });
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />

                  <Form.Label className="mt-2 text-danger">
                    {errors.hospitalInfo && errors.hospitalInfo.message}
                  </Form.Label>
                </div>
              </Form.Group>
            </>
          )
        );
      //////////////////////////////////////////////////
      //////////////////////////////////////////////////
      case "Description+Policestation":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3 btnClick">
                        {questions_obj.answer === "Not Available" && (
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() =>
                              changeFormTemplateDataforSpecificSection([
                                15,
                                questions_obj.question,
                                questions_obj_key,
                              ])
                            }
                          >
                            <img
                              src={String(position)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Button>
                        )}
                        {questions_obj.answer !== "Not Available" && (
                          <Nav.Link
                            className="link"
                            target="_blank"
                            href={
                              "https://www.google.com/maps/dir/" +
                              getLatitude +
                              "," +
                              getLongitude +
                              "/" +
                              questions_obj.lat +
                              "," +
                              questions_obj.long +
                              "/@" +
                              getLatitude +
                              "," +
                              getLongitude +
                              ",12z"
                            }
                          >
                            <img
                              src={String(map_directions)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Nav.Link>
                        )}
                        {questions_obj.phone_number !== "" && (
                          <Nav.Link
                            className="link"
                            href={"tel:" + questions_obj.phone_number}
                          >
                            <span className="icon-call">&#128381;</span>
                          </Nav.Link>
                        )}
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+ToDirection":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              16,
                              [
                                questions_obj.question,
                                [getLatitude, getLongitude],
                                [questions_obj.lat, questions_obj.long],
                              ],
                              3,
                            ])
                          }
                        >
                          <img
                            src={String(map_directions)}
                            alt="reset"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === ""
                            ? setError1("directionsToHospital", {
                              message: "This field is required",
                            })
                            : setError1("directionsToHospital", {
                              message: "",
                            });
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {errors.directionsToHospital &&
                        errors.directionsToHospital.message}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+FromDirection":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              16,
                              [
                                questions_obj.question,
                                [getLatitude, getLongitude],
                                [questions_obj.lat, questions_obj.long],
                              ],
                              3,
                            ])
                          }
                        >
                          <img
                            src={String(map_directions)}
                            alt="reset"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === ""
                            ? setError1("directionsToHospital", {
                              message: "This field is required",
                            })
                            : setError1("directionsToHospital", {
                              message: "",
                            });
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {errors.directionsToHospital &&
                        errors.directionsToHospital.message}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+Firestation":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              16,
                              [
                                questions_obj.question,
                                [getLatitude, getLongitude],
                                [questions_obj.lat, questions_obj.long],
                              ],
                              3,
                            ])
                          }
                        >
                          <img
                            src={String(map_directions)}
                            alt="reset"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        placeholder="Please enter..."
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                          e.target.value === "" &&
                            questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      case "Text":
        return (
          questions_obj.visibility && (
            <>
              {questions_obj.fileRequired === "Yes" &&
                dialoguetopicvalues.topic !== "" ? (
                <TextQuestion
                  questions_obj={questions_obj}
                  dialoguetopicvalues={dialoguetopicvalues}
                  onChangeFormTemplate={onChangeFormTemplate}
                  sectionId={sectionId}
                  getError1={getError1}
                />
              ) : (
                <>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}

                  <Form.Control
                    className="rounded-0"
                    type="text"
                    disabled={questions_obj.readonly === true ? true : false}
                    value={questions_obj.answer}
                    required={questions_obj.state === "Required" ? true : false}
                    placeholder="Please enter..."
                    onChange={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                      e.target.value === "" &&
                        questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  />
                </>
              )}
            </>
          )
        );

      case "Radio+Image+Document":
        return (
          <GenericQuestionTypes
            questions_obj={questions_obj}
            onChangeFormTemplate={onChangeFormTemplate}
            sectionId={sectionId}
            getUploadSingleImage={getUploadSingleImage}
          />
        );

      case "Radio":
        return (
          questions_obj.visibility &&
          (
            questions_obj.hasOwnProperty("addDescription") ? (
              <div key={`inline-radio-${questions_obj.questionId}`} className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">
                    {questions_obj.state === "Required" ? "*" : ""}
                  </span>
                  {questions_obj.question}
                </Form.Label>
                <br />

                {questions_obj.radio_options.values.map((obj_v, i) => {
                  return (
                    <Form.Check
                      inline
                      label={obj_v}
                      name={`group-${questions_obj.questionId}`}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-${i}-${questions_obj.questionId}`}
                      defaultChecked={questions_obj.addDescription === obj_v}
                      required={questions_obj.state === "Required" ? true : false}
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          addDescriptionVisibility: questions_obj.showCommentBox[i],
                          addDescriptionVal: obj_v,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  );
                })}
                {questions_obj.description_model[0].visible &&
                  <>
                    <Form.Control
                      className="rounded-0 mb-3"
                      as="textarea"
                      rows={5}
                      placeholder="Please Enter..."
                      value={questions_obj.answer}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });

                        if (e.target.value === "") {
                          setError("addDescriptionComment", {
                            message: "This is required field.",
                          });
                        } else {
                          setError("addDescriptionComment", {
                            message: "",
                          });
                        }

                      }}
                    />
                    <Form.Label className="mt-2 text-danger">
                      {errors.addDescriptionComment &&
                        errors.addDescriptionComment.message}
                    </Form.Label>
                  </>
                }
              </div>
            )
              :
              questions_obj.hasOwnProperty("radio_options") ? (
                <div
                  key={`inline-radio-${questions_obj.questionId}`}
                  className="mb-3"
                >
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  <br />

                  {questions_obj.radio_options.values.map((obj_v, i) => {
                    return (
                      <Form.Check
                        inline
                        label={obj_v}
                        name={`group-${questions_obj.questionId}`}
                        type="radio"
                        className="mb-3"
                        id={`inline-radio-${i}-${questions_obj.questionId}`}
                        defaultChecked={questions_obj.answer === obj_v}
                        required={questions_obj.state === "Required" ? true : false}
                        onClick={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: obj_v,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    );
                  })}

                  {questions_obj.imageUpload && (
                    <Form.Control
                      className="rounded-0 custom-file-input mb-3"
                      type="file"
                      onChange={(e) => {
                        getUploadImageUrlofImage(e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <div className="mb-3">
                      {"Uploaded Image Count:" +
                        questions_obj.multiple_picture.length}
                    </div>
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                      {questions_obj.multiple_picture.map((obj, i) => (
                        <Col className="item-w m-1  imgDiv">
                          <div
                            className="removeImg"
                            onClick={() =>
                              removeImgData({
                                sectionId: sectionId,
                                questionId: questions_obj.questionId,
                                idx: i,
                              })
                            }
                          >
                            x
                          </div>
                          <img
                            src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                            alt={i}
                            className="shadow-sm bg-light rounded"
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {questions_obj.hasOwnProperty("description_model")
                    ? questions_obj.description_model[0].visible && (
                      <Form.Control
                        className="rounded-0 mb-3"
                        as="textarea"
                        rows={5}
                        value={questions_obj.description_model[0].text}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            desc_text: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    )
                    : null}
                </div>
              ) :


                (
                  <div
                    key={`inline - radio - ${questions_obj.questionId} `}
                    className="mb-3"
                  >
                    <Form.Label>
                      <span className="text-danger bold-font">
                        {questions_obj.state === "Required" ? "*" : ""}
                      </span>
                      {questions_obj.question}
                    </Form.Label>
                    {questions_obj.tooltip_message !== "" &&
                      questions_obj.tooltip_message !== undefined && (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              {questions_obj.tooltip_message}
                            </Tooltip>
                          }
                        >
                          <span className="icon-info">i</span>
                        </OverlayTrigger>
                      )}
                    <br />
                    <Form.Check
                      inline
                      label="Yes"
                      name={`group - ${questions_obj.questionId} `}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "Yes"}
                      required={questions_obj.state === "Required" ? true : false}
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "Yes",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                          visibility: questions_obj.visibility,
                        });
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name={`group - ${questions_obj.questionId} `}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "No"}
                      required={questions_obj.state === "Required" ? true : false}
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "No",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                          visibility: questions_obj.visibility,
                        });
                      }}
                    />
                  </div>
                ))
        );
      case "Radio+VideoCall":
        return questions_obj.visibility ? (
          <div
            key={`inline-radio-${questions_obj.questionId}`}
            className="mb-3"
          >
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            <br />

            {questions_obj.radio_options.values.map((obj_v, i) => {
              return (
                <Form.Check
                  inline
                  label={obj_v}
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${i}-${questions_obj.questionId}`}
                  defaultChecked={questions_obj.answer === obj_v}
                  required={questions_obj.state === "Required" ? true : false}
                  onClick={(e) => {
                    onChangeFormTemplate({
                      sectionId: sectionId,
                      answer: obj_v,
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                    if (obj_v === "No") {
                      setvideodisplayes(false);
                      initiateVideoCall1(DECRYPTED_DATA(), obj_v);
                    } else if (!videodisplayes) {
                      setvideodisplayes(true);
                      initiateVideoCall1(DECRYPTED_DATA(), obj_v);
                    }
                  }}
                />
              );
            })}
          </div>
        ) : null;

      case "Signature":
        return (
          questions_obj.visibility && (
            <div className="col-3">
              <Form.Label className="bold-font">
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}

              <div className="col-3">
                <Signature
                  setSignBase64Uri={setSignBase64Uri}
                  imgName="signature.png"
                  resetSign={(e) => {
                    onChangeFormTemplate({
                      sectionId: sectionId,
                      signature: "",
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                  }}
                />

                {questions_obj.signature !== "" && (
                  <div className="p-1 m-1">
                    <img
                      src={
                        getUrlWithQueryString(questions_obj.signature) +
                        IMG_TOKEN()
                      }
                      alt={
                        questions_obj.picName !== ""
                          ? questions_obj.picName
                          : null
                      }
                      className="shadow-sm bg-light rounded img-size_preview"
                    />
                  </div>
                )}
              </div>
            </div>
          )
        );

      case "Terms&condition":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Check
                  inline
                  name="group1"
                  defaultChecked={questions_obj.checkbox_model[0].value}
                  label={questions_obj.checkbox_model[0].text}
                  onChange={(e) => {
                    questions_obj.checkbox_model[0].value === true &&
                      questions_obj.state === "Required"
                      ? setError1("This field is required")
                      : setError1("");
                    onChangeFormTemplate({
                      sectionId: sectionId,
                      checkbox_value: !questions_obj.checkbox_model[0].value,
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                  }}
                  type="checkbox"
                  id={`inline - checkbox - 1`}
                />
              </Form.Group>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );
      case "Dropdown+Image+Comment":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Select
                aria-label={`select - ${questions_obj.questionId} `}
                className="rounded-0"
                defaultValue={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              >
                <option value="">Select</option>
                {questions_obj.options.map((obj) => (
                  <option value={obj}>{obj}</option>
                ))}
              </Form.Select>
              <br />
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                            ImageUrl: obj,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )
        );

      ///////////////////////////////
      case "Multiple-Image":
        return (
          questions_obj.visibility && (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />

              {
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (questions_obj.multiple_picture.length > 4) {
                      alert("You can only upload upto 5 Images");
                      return;
                    }

                    if (file.size >= 5e6) {
                      const image = await resizeFile(file);
                      getUploadImageUrlofImage(image);
                      e.target.value = null;
                    } else {
                      getUploadImageUrlofImage(file);
                      e.target.value = null;
                    }
                  }}
                />
              }
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count: " +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                            ImageUrl: obj,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          )
        );

      //////////////////////////////
      case "Multiple-questions":
        return (

          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="form-3-2" className="mb-3">
                <DailyTaskforSIF
                  questions_obj_key={questions_obj_key}
                  form_template={form_template}
                  setForm_template={setForm_template}
                  getProceed={form_template.findIndex(i => i.sectionId === sectionId)}
                  questions_obj={questions_obj}
                  modalMapCustomHandler={modalMapCustomHandler}
                  sectionId={sectionId}
                />
                <div className="d-flex justify-content-center m-3"></div>
              </Form.Group>
            </>
          )
        );

      case "Dropdown+Description":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Select
                aria-label={`select - ${questions_obj.questionId} `}
                className="rounded-0"
                defaultValue={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              >
                <option value="">Select</option>
                {questions_obj.options.map((obj) => (
                  <option value={obj}>{obj}</option>
                ))}
              </Form.Select>
              <br />
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
            </>
          )
        );

      case "Dropdown+Image":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              {
                <Form.Group controlId="form-2-4" className="mb-3">
                  <Form.Select
                    aria-label="typeofstructure"
                    className="rounded-0"
                    defaultValue={
                      questions_obj.answer !== "" &&
                        getFirstPart(questions_obj.answer) !== "Other "
                        ? questions_obj.answer
                        : getFirstPart(questions_obj.answer).replace(
                          /^\s+|\s+$/gm,
                          ""
                        )
                    }
                    onChange={(e) => {
                      changeFormTemplateDataforSpecificSection([
                        43,
                        e.target.value,
                        questions_obj.questionId,
                      ]);
                      e.target.value === "" &&
                        questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  >
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <option value="">Select</option>
                    {questions_obj.options.map((obj) => (
                      <option value={obj}>{obj}</option>
                    ))}
                  </Form.Select>

                  {typeofstructure ? (
                    <>
                      <Form.Control
                        className="rounded-0 mt-3"
                        type="text"
                        value={getSecondPart(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === "" &&
                            questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          changeFormTemplateDataforSpecificSection([
                            44,
                            e.target.value,
                            questions_obj.questionId,
                          ]);
                        }}
                      />
                      <Form.Label className="mt-2 text-danger">
                        {getError1}
                      </Form.Label>
                    </>
                  ) : null}
                </Form.Group>
              }

              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                            ImageUrl: obj,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )
        );

      //////////////////////////////////////////
      case "Radio+Sub-question":
        return (
          questions_obj.visibility && (
            <>
              <Col>
                <Form.Label>
                  <span className="text-danger bold-font">
                    {questions_obj.state === "Required" ? "*" : ""}
                  </span>
                  {questions_obj.question}
                </Form.Label>
                {questions_obj.tooltip_message !== "" &&
                  questions_obj.tooltip_message !== undefined && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {questions_obj.tooltip_message}
                        </Tooltip>
                      }
                    >
                      <span className="icon-info">i</span>
                    </OverlayTrigger>
                  )}

                <Form.Group controlId="form-3-4" className="mb-3">
                  <div key="inline-radio" className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name={`group-${questions_obj.questionId}`}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "Yes"}
                      required={
                        questions_obj.state === "Required" ? true : false
                      }
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "Yes",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name={`group-${questions_obj.questionId}`}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "No"}
                      required={
                        questions_obj.state === "Required" ? true : false
                      }
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "No",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              {questions_obj.answer === "Yes" ? (
                <>
                  <Row xs={12} className="m-2">
                    <Col>
                      <Form.Group controlId="form-3-3-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {questions_obj.subQuestion[0].question}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group3-1"
                            type="radio"
                            id={`inline-radio-${questions_obj.questionId}`}
                            checked={
                              questions_obj.subQuestion[0].answer === "Yes"
                            }
                            onClick={(e) =>
                              onChangeFormTemplate({
                                sectionId: sectionId,
                                answer: (questions_obj.subQuestion[0].answer =
                                  "Yes"),
                                questionId:
                                  questions_obj.subQuestion[0].questionId,
                                required: questions_obj.state,
                              })
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group3-1"
                            type="radio"
                            id={`inline-radio-${questions_obj.questionId}`}
                            checked={
                              questions_obj.subQuestion[0].answer === "No"
                            }
                            onClick={(e) =>
                              onChangeFormTemplate({
                                sectionId: sectionId,
                                answer: (questions_obj.subQuestion[0].answer =
                                  "No"),
                                questionId:
                                  questions_obj.subQuestion[0].questionId,
                                required: questions_obj.state,
                              })
                            }
                          />
                        </div>

                        {questions_obj.subQuestion[0].answer === "Yes" &&
                          questions_obj.subQuestion[0].pictureRequired ===
                          "Yes" ? (
                          <>
                            {questions_obj.pictureRequired && (
                              <Form.Control
                                className="rounded-0 custom-file-input mb-3"
                                type="file"
                                onChange={async (e) => {
                                  const file = e.target.files[0];

                                  getUploadSingleImage(
                                    [
                                      file,
                                      sectionId,
                                      questions_obj.questionId,
                                      "subQuestionPic",
                                    ],
                                    questions_obj
                                  );
                                }}
                              />
                            )}

                            <Form.Label className="mt-2 mb-3">
                              <b>
                                {questions_obj.subQuestion[0].picture !== "" &&
                                  "1 File Uploaded"}
                              </b>
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )
        );

      //////////////////////////////////
      case "Dropdown":
        return (
          <>
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.apiDependentDropdown === true ? (
              <>
                <DropdownFromApi
                  UpdatedValueofDropdownApi={UpdatedValueofDropdownApi}
                  handleDropdownfromApi={handleDropdownfromApi}
                  SelectedOptionofDropdownApi={SelectedOptionofDropdownApi}
                  setSelectedOptionofDropdownApi={
                    setSelectedOptionofDropdownApi
                  }
                  emailError={emailError}
                  setEmailError={setEmailError}
                  questions_obj={questions_obj}
                  onChangeFormTemplate={onChangeFormTemplate}
                  sectionId={sectionId}
                />
              </>
            ) : (
              <>
                <Form.Select
                  className="rounded-0"
                  defaultValue={
                    questions_obj.answer !== "" &&
                      getFirstPart(questions_obj.answer) !== "Other "
                      ? questions_obj.answer
                      : getFirstPart(questions_obj.answer).replace(
                        /^\s+|\s+$/gm,
                        ""
                      )
                  }
                  required={questions_obj.state === "Required" ? true : false}
                  id={uuidv4()}
                  onChange={(e) => {
                    onChangeFormTemplate({
                      sectionId: sectionId,
                      Dropdown_answer: e.target.value,
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                    e.target.value === "" && questions_obj.state === "Required"
                      ? setError1("This field is required")
                      : setError1("");
                  }}
                >
                  <option value="">Select</option>
                  {questions_obj.options.map((obj, k) => (
                    <option key={k} value={obj}>
                      {obj}
                    </option>
                  ))}
                </Form.Select>
                {questions_obj.answer !== "" &&
                  /^Other-/.test(questions_obj.answer) ? (
                  <>
                    <Form.Control
                      className="rounded-0 mt-3"
                      type="text"
                      value={getSecondPart(questions_obj.answer)}
                      id={uuidv4()}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          OtherOption: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                        e.target.value === "" &&
                          questions_obj.state === "Required"
                          ? setError1("This field is required")
                          : setError1("");
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </>
        );

      case "Text+AutoFill":
        const filteredOptions = searchText
          ? selectedOptions.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
          )
          : selectedOptions;
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <div className="HazardsDiv">
                <Form.Group controlId="form-7-1" className="mb-3">
                  <Select
                    value={selectedOption}
                    options={
                      questions_obj.useSearchText
                        ? filteredOptions
                        : searchText
                          ? [
                            ...selectedOptions,
                            { value: searchText, label: searchText },
                          ]
                          : [...selectedOptions]
                    }
                    onChange={handleChange}
                    onInputChange={handleSelect}
                    isSearchable={true}
                    isOptionDisabled={(option) =>
                      questions_obj.useSearchText
                        ? !selectedOptions.find((o) => o.value === option.value)
                        : false
                    }
                    formatOptionLabel={
                      questions_obj.useSearchText
                        ? (option) =>
                          option.value === searchText
                            ? null
                            : option.value && option.label
                              ? option.label
                              : null
                        : null
                    }
                    hideDisabledOptions={true}
                  />
                </Form.Group>
              </div>
            </>
          )
        );

      ///////////////////////////////////////////////////
      case "Multi-select":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}

              <Select
                className="rounded-0"
                type="text"
                value={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              />

              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      case "Description+Location":
        return (
          questions_obj.visibility && (
            <Row xs={12}>
              <Col>
                <Form.Group controlId="form-2-2" className="mb-3">
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  {questions_obj.readonly === true ? (
                    <div className="position-relative ">
                      <Form.Control
                        className="rounded-0"
                        disabled
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onChange={(e) => {
                          e.target.value === ""
                            ? setError1("", {
                              message: "This field is required",
                            })
                            : setError1("", { message: "" });
                          changeFormTemplateDataforSpecificSection([
                            1,
                            e.target.value,
                            questions_obj.questionId,
                            questions_obj_key,
                            sectionId,
                          ]);
                        }}
                      />
                      <Form.Label className="mt-2 text-danger">
                        {getError1}
                      </Form.Label>
                    </div>
                  ) : (
                    <div className="position-relative ">
                      <div className="position-absolute top-0 end-0 sig-controls">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              15,
                              questions_obj.question,
                              questions_obj_key,
                              sectionId,
                            ])
                          }
                        >
                          <img
                            src={String(position)}
                            alt="reset sign"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onChange={(e) => {
                          e.target.value === ""
                            ? setError1("", {
                              message: "This field is required",
                            })
                            : setError1("", { message: "" });
                          changeFormTemplateDataforSpecificSection([
                            1,
                            e.target.value,
                            questions_obj.questionId,
                            questions_obj_key,
                            sectionId,
                          ]);
                        }}
                      />
                      <Form.Label className="mt-2 text-danger">
                        {getError1}
                      </Form.Label>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )
        );

      default:
        return (
          <>
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.tooltip_message !== "" &&
              questions_obj.tooltip_message !== undefined && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {questions_obj.tooltip_message}
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
            <Form.Control
              className="rounded-0"
              type="text"
              value={questions_obj.answer}
              required={questions_obj.state === "Required" ? true : false}
              onChange={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError1("This field is required")
                  : setError1("");
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
          </>
        );
    }
  }, [questions_obj, dialoguetopicvalues, getWeatherHours, getEmployeeDropdownval]);

  return (
    <>
      <Form.Group
        controlId={`form - ${questions_obj.questionId} `}
        className={questions_obj.visibility ? "mb-3" : ""}
      >
        {questions_obj.visibility === true ? return_control : ""}
        {entiresection.repeatable === true &&
          questions_obj.repeatable === true ? (
          <div className="d-flex justify-content-center m-3">
            <Button
              className=" bd-highlight"
              size="sm"
              onClick={(e) =>
                DeficiencyDetailsQAdd(questions_obj, questions_obj_key)
              }
            >
              + Add Custom Corrective Action
            </Button>
          </div>
        ) : null}
      </Form.Group>
      {questions_obj.questionType === "Image+Certificate" &&
        typeof getCertifyData.certificateModel !== "undefined" && (
          <Form.Group className="CertificateSec">
            {typeof getCertifyData.certificateModel !== "undefined" &&
              getCertifyData.certificateModel.map((obj, k) => {
                return (
                  <CertificateListSiteObsrForm
                    key={k}
                    certificateDate={obj.certificateDate}
                    certificateName={obj.certificateName}
                    certificatePicture={obj.certificatePicList[0]}
                    certificateMultipleName={obj.certificateMultipleName}
                    singleOrMulti={
                      obj.certificateName === undefined ? "multi" : "single"
                    }
                    certificateSeqId={k}
                    setCertifyData={setCertifyData}
                    getCertifyData={getCertifyData}
                    onChangeCertificate={onChangeCertificate}
                  />
                );
              })}
          </Form.Group>
        )}
      {showMdl1 && (
        <ModalCertificateSiteObsrForm
          showMdl={showMdl1}
          setShowMdl={setShowMdl1}
          dataMdl={getDataMdl1}
          setCertifyData={setCertifyData}
          getCertifyData={getCertifyData}
          onChangeCertificate={onChangeCertificate}
        />
      )}
    </>
  );
}
