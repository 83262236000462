import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import _ from "lodash";
import { googleMapsKey } from "../../utils/Constant";
import { Modal, Form } from "react-bootstrap"; // Import the Modal component from the library you are using for modals

const containerStyle = {
  width: "100%",
  height: "300px",
};

function ManualGMap({ center, setLatLng911, showModal, closeModal }) {
  const [_address, _setAddress] = useState("");
  const [zget, zset] = useState(0);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries: ["places"],
  });
  const [markerposition, setMarkerposition] = useState(center);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState();
  const [bounds, setBounds] = useState();
  const [getcenter, setCenter] = useState(null);
  const [getAddress, setAddress] = useState("");
  const onBoundsChanged = () => {
    setBounds(map.getBounds());
  };

  const onPlacesChanged = (map) => {
    if (searchBox != null) {
      const places = searchBox.getPlaces();
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      const nextMarkers = places.map((place) => ({
        position: place.geometry.location,
      }));

      const nextCenter = _.get(nextMarkers, "0.position", getcenter);
      let lat = nextCenter.lat();
      let lng = nextCenter.lng();

      setCenter({ lat: lat, lng: lng });
      setMarkerposition({ lat: lat, lng: lng });
      getReverseGeocodingData(lat, lng);
    }
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    bounds.extend({ lat: 85, lng: 180 });
    bounds.extend({ lat: 35, lng: -85 });
    bounds.extend({ lat: 0, lng: 40 });
    bounds.extend({ lat: -30, lng: 25 });
    bounds.extend({ lat: 60, lng: 100 });
    bounds.extend({ lat: -25, lng: 140 });
    map.fitBounds(bounds);
    map.setZoom(3);
    setMap(map);
    // const zoom = 4;
    // const _goo = new window.google.maps.Map(map, {
    //     zoom,
    //     center
    // });
    // map.fitBounds(_goo);
    // setMap(_goo)
    // console.log('onload map::', map.getZoom(), map)
    getReverseGeocodingData(center.lat, center.lng);
  }, []);

  const onClick = useCallback(
    function callback(map) {
      let _markerposition = _.cloneDeep(markerposition);
      _markerposition = { lat: map.latLng.lat(), lng: map.latLng.lng() };
      setMarkerposition(_markerposition);

      //  setCenter(_markerposition);
      getReverseGeocodingData(map.latLng.lat(), map.latLng.lng());
      setAddress(map.latLng.lat(), map.latLng.lng());
    },
    [getReverseGeocodingData]
  );

  function getReverseGeocodingData(lat, lng) {
    // debugger

    if (!window.google || !window.google.maps || !isLoaded) {
      return;
    }
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        return;
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (results && results.length > 0) {
        // console.log(results);
        var address = results[0].formatted_address;
        // console.log('vicky', address);
        address += "\n(" + lat + "," + lng + ")";
        setLatLng911({ lat: lat, lng: lng });
        setAddress(address);
        _setAddress(address);
      } else {
      }
    });
  }
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (showModal) {
      zset(17);
      getReverseGeocodingData(center.lat, center.lng);
    }
  }, [showModal, center]);

  const handleAddLocation = () => {
    // Save the selected location and close the modal
    setLatLng911({ lat: markerposition.lat, lng: markerposition.lng });
    setAddress(_address);
    closeModal();
  };

  const handleFocus = (event) => {
    event.target.blur();
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          Unable to get your precise location, please confirm your location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoaded && (
          <div className="customTxtDiv">
            <StandaloneSearchBox
              onPlacesChanged={() => onPlacesChanged()}
              onLoad={(ref) => setSearchBox(ref)}
              bounds={bounds}
              // controlPosition={''}
            >
              <input type="text" placeholder="Search" id="gMapTextBox" />
            </StandaloneSearchBox>
          </div>
        )}

        {isLoaded && (
          <GoogleMap
            center={getcenter}
            zoom={zget}
            onBoundsChanged={() => onBoundsChanged()}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onClick}
            id="gMapCheck"
            options={{ minZoom: 1 }}
          >
            <div>
              <Marker position={markerposition}>
                {_address !== "" ? (
                  <InfoWindow options={{ maxWidth: 250 }}>
                    <span
                      style={{
                        maxHeight: `100px`,
                        maxHeight: `60px`,
                        fontSize: `13px`,
                        overflow: `auto`,
                      }}
                    >
                      {getAddress}
                    </span>
                  </InfoWindow>
                ) : null}
              </Marker>
            </div>
          </GoogleMap>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Form.Control
          className="rounded-0 gmapTextArea"
          rows={2}
          value={getAddress}
          style={{ cursor: "default", userSelect: "none" }}
          onFocus={handleFocus}
        />

        <button
          className="btn btn-primary"
          disabled={getAddress === ""}
          onClick={handleAddLocation}
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ManualGMap;
