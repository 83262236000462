import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useEffect } from "react";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import SelectionSectionforIjha from "./SelectionSectionforIjha";
import SelectedSectionFormforIJHA from "./SelectedSectionFormforIJHA";
import arrow_up from "../../assets/Icons/arrow_up.png";
import {
  EMP_ID,
  DECRYPTED_DATA,
  TOKEN,
  UserGroupId,
} from "../../utils/Variable";
import { GetJWTToken } from "../../api/JitsiJwttoken.api";
import { getCertificateOptionList } from "../../api/SiteObservationCertificate.api";
import ManualGMap from "../../components/GMap/ManualGMap";
export default function SiteInspectionForm({}) {
  let s = useLocation();
  const [getLoc, setLoc] = useState(
    s.state.getLoc === undefined ? { lat: "", lng: "" } : s.state.getLoc
  );

  const [getLocationavailable, setLocationavailable] = useState({
    lat: s.state.SiteInspectionForm.latitude,
    lng: s.state.SiteInspectionForm.longitude,
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [getAddress, setAddress] = useState("");
  const [showGMap, setShowGMap] = useState(false);
  const [getErrMsg, setErrMsg] = useState("");
  const [getReportRequired, setReportRequired] = useState(s.state.setRequired);
  const [getReportfilled, setReportfilled] = useState(s.state.setRequired);
  const [getFormData, setFormData] = useState(s.state.getFormData1[0]);
  const [getCertificateOptionListD, setCertificateOptionListD] = useState([]);
  const [getSiteInspectionForm, setSiteInspectionForm] = useState(
    s.state.SiteInspectionForm
  );
  const [form_template, setForm_template] = useState(
    s.state.SiteInspectionForm.form_template
  );
  const [getSelectedSection, setSelectedSection] = useState([]);

  const [getVisiblityComp, setVisiblityComp] = useState({
    selectionSection: true,
    selectedSectionForm: false,
  });
  const [getProceed, setProceed] = useState(0);
  const [getProceed1, setProceed1] = useState(0);
  const [getLoading, setLoading] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  const ArrayForElements = form_template
    .map((item) => {
      if (item.hasOwnProperty("webvisibility")) {
        item.visibility = false;
        return null;
      }
      return item;
    })
    .filter(Boolean);

  const defaultSelectedSection = () => {
    let _getSelectedSection = ArrayForElements.map((obj) => {
      return {
        sectionId: obj.sectionId,
        sectionName: obj.sectionName,
        checked_val: obj.visibility,
        webCompulsory: obj.webCompulsory,
      };
    });
    setSelectedSection(_getSelectedSection);
  };

  let sectionCorrective;
  let sectionDeficiency;
  let sectionCustomCorrective;

  const targetSectionId = "17";

  const correctiveIndex = form_template.findIndex(
    (item) => item.sectionId === targetSectionId
  );

  if (correctiveIndex !== -1) {
    if (form_template[correctiveIndex].sectionId === "17") {
      sectionCorrective = form_template[correctiveIndex].questions[0];
      sectionDeficiency = form_template[correctiveIndex].questions[0];
    } else if (form_template[correctiveIndex].sectionId === "17") {
      sectionDeficiency = form_template[correctiveIndex].questions[0];
    }
  } else {
  }

  if (form_template.some((section) => section.sectionId === "18")) {
    const pageIndexofCustomCorrective = form_template.findIndex(
      (item) => item.sectionId === "18"
    );

    sectionCustomCorrective =
      form_template[pageIndexofCustomCorrective].questions[0];
  } else {
  }

  let param = {
    user: {
      moderator: true,
      name:
        DECRYPTED_DATA().firstName.replace(/\s+/g, "") +
        " " +
        DECRYPTED_DATA().lastName.replace(/\s+/g, ""),
      id: EMP_ID().replace(/['"]+/g, ""),
      avatar: DECRYPTED_DATA().profile_img_url,
      email: DECRYPTED_DATA().emailId,
    },
  };

  useEffect(() => {
    fetchCertificateOptionList();
  }, []);

  async function fetchCertificateOptionList() {
    await getCertificateOptionList(setCertificateOptionListD, setLoading);
  }

  useEffect(() => {
    if (!_.isEmpty(getFormData) && getFormData.formId === "11")
      NewJitsiAPiCall();
  }, []);

  async function NewJitsiAPiCall() {
    await GetJWTToken(param);
  }

  const onChangeSectionSelection = (updated_val) => {
    let _getSelectedSection = _.cloneDeep(getSelectedSection);
    _getSelectedSection = _getSelectedSection.map((obj) => {
      if (obj.sectionId === updated_val[0]) obj.checked_val = updated_val[1];

      return obj;
    });
    setSelectedSection(_getSelectedSection);
  };

  const onChangeVisibilityCheck = (updated_val) => {
    let visibilityCheck = form_template.map((obj1) => {
      if (obj1.sectionId === updated_val[0]) obj1.visibility = updated_val[1];
      if (obj1.webCompulsory === true) {
        obj1.visibility = true;
      }
      return obj1;
    });

    setForm_template(visibilityCheck);
  };
  const onClickSelectAllSection = (_disable_sel_proceed) => {
    let _getSelectedSection = _.cloneDeep(getSelectedSection);
    _getSelectedSection = _getSelectedSection.map((obj) => {
      obj.checked_val = _disable_sel_proceed;
      return obj;
    });
    setSelectedSection(_getSelectedSection);
    setReportRequired(false);

    let visibilityCheck = form_template.map((obj1) => {
      obj1.visibility = _disable_sel_proceed;
      return obj1;
    });

    setForm_template(visibilityCheck);
  };
  const onClickSelectionProceed = () => {
    if (s.state.getFormData1[0].location_required === false) {
      setLoc({
        lat: s.state.getFormData1[0].default_location.lat,
        lng: s.state.getFormData1[0].default_location.long,
      });
      setErrMsg("");
      let _getVisiblityComp = _.cloneDeep(getVisiblityComp);
      _getVisiblityComp.selectedSectionForm = true;
      _getVisiblityComp.selectionSection = false;
      setVisiblityComp(_getVisiblityComp);
      setReportRequired(false);
    } else {
      if (
        (getLoc !== undefined && getLoc.lat !== "" && getLoc.lng !== "") ||
        (s.state.SiteInspectionForm.latitude &&
          s.state.SiteInspectionForm.longitude)
      ) {
        setErrMsg("");
        let _getVisiblityComp = _.cloneDeep(getVisiblityComp);
        _getVisiblityComp.selectedSectionForm = true;
        _getVisiblityComp.selectionSection = false;
        setVisiblityComp(_getVisiblityComp);
        setReportRequired(false);
      } else {
        setShowGMap(true);
      }
    }
  };
  const closeAlert = (e) => {
    setErrMsg("");
    window.location.pathname = "/create-SiteInspection";
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  // useEffect(() => {
  //   var _latlng;

  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       _latlng = _.cloneDeep(getLoc);
  //       _latlng.lat = position.coords.latitude;
  //       _latlng.lng = position.coords.longitude;

  //       setLoc(_latlng);
  //     });
  //   } else {
  //     setErrMsg("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  useEffect(() => {
    !_.isEmpty(getSiteInspectionForm) && defaultSelectedSection();
    scrollUp();
    var _latlng;
    if (getFormData.location_required === true) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          _latlng = _.cloneDeep(getLoc);
          _latlng.lat = position.coords.latitude;
          _latlng.lng = position.coords.longitude;

          setLoc(_latlng);
        });
      } else {
        setErrMsg("Geolocation is not supported by this browser.");
      }
    }
  }, [getProceed, getProceed1]);
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleCloseModal = () => {
    setShowGMap(false);
  };

  const handleLocationSelection = (latlng) => {
    setSelectedLocation(latlng);
    setLoc(latlng);
  };

  const scrollDown = () => {
    let _totalHeight = document.body.offsetHeight;
    window.scrollTo({ top: _totalHeight, behavior: "smooth" });
  };
  const _disable_sel_proceed = getSelectedSection.every(
    (obj) => obj.checked_val === false
  );

  return (
    <Container className="margin-container">
      <h4 className="pt-2">{getFormData.form_name}</h4>
      {showGMap && (
        <ManualGMap
          center={0}
          setLatLng911={handleLocationSelection}
          showModal={showGMap}
          closeModal={handleCloseModal}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      )}
      {getErrMsg !== "" && (
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={6} md={6}>
            <Alert variant="danger" onClose={(e) => closeAlert(e)} dismissible>
              {getErrMsg}
            </Alert>
          </Col>
        </Row>
      )}

      {getVisiblityComp.selectionSection && (
        <SelectionSectionforIjha
          getSelectedSection={getSelectedSection}
          onChangeSectionSelection={onChangeSectionSelection}
          onClickSelectAllSection={onClickSelectAllSection}
          onChangeVisibilityCheck={onChangeVisibilityCheck}
          _disable_sel_proceed={_disable_sel_proceed}
          onClickSelectionProceed={onClickSelectionProceed}
          getLoading={getLoading}
        />
      )}
      {getVisiblityComp.selectedSectionForm && (
        <SelectedSectionFormforIJHA
          sectionCorrective={sectionCorrective}
          sectionDeficiency={sectionDeficiency}
          sectionCustomCorrective={sectionCustomCorrective}
          getFormData={getFormData}
          getSelectedSection={getSelectedSection}
          // namesToDeleteSet={namesToDeleteSet}
          getReportfilled={getReportfilled}
          selectedSectionData={getSelectedSection}
          form_template_p={form_template}
          reportRequired={getReportRequired}
          getLoc={getLoc}
          setLoc={setLoc}
          getLocationavailable={getLocationavailable}
          getCertificateOptionListD={getCertificateOptionListD}
        />
      )}

      {getLoading && <Loading width="32" height="32" />}
      <Button
        onClick={scrollDown}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed topToBottom end-0 translate-middle "
        value="bottom"
        id="back-to-down"
      >
        <img
          src={String(arrow_up)}
          alt="scroll bottom"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
      <Button
        onClick={scrollUp}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed bottom-0 end-0 translate-middle "
        value="top"
        id="back-to-up"
      >
        <img
          src={String(arrow_up)}
          alt="scroll top"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
    </Container>
  );
}
