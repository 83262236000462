import { TOKEN } from "../utils/Variable";
import { URL } from "../utils/Constant";

export const GetDropdownValues = async (dataJSON) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(
    URL.GetDropdownValues + `?filter=${dataJSON["filter"]}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    return data.data;
  }
};
