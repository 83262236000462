import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import SelectedSectionFormforIJHA from "./SelectedSectionFormforIJHA";
import { getFormIjhaApi } from "../../api/CreateiJHA.api";
import arrow_up from "../../assets/Icons/arrow_up.png";
import AddCrewMemberDynamic from "../CrewMember/AddCrewMemberDynamic";
import { get911AddressInfoApi } from "../../api/CreateiJHAforIJHADynamic.api";
import ManualGMap from "../../components/GMap/ManualGMap";
export default function IjhaForm({}) {
  let s = useLocation();
  const [getNewReport, setNewReport] = useState(s.state.reportIJHA);
  const [form_template, setForm_template_API] = useState();
  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [getLocationavailable, setLocationavailable] = useState(s.state.getLoc);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [getAddress, setAddress] = useState("");
  const [showGMap, setShowGMap] = useState(false);
  const [getErrMsg, setErrMsg] = useState("");
  const [getIsAddCrew, setIsAddCrew] = useState(false);
  const [getAllCrewMemData, setAllCrewMemData] = useState([]);
  const [getFormData, setFormData] = useState(s.state.getFormData[0]);
  const [getCrewUpdate, setCrewUpdate] = useState(false);
  const [getProceed, setProceed] = useState(0);
  const [getSelectedSection, setSelectedSection] = useState();
  const [getLoading, setLoading] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);
  const scrollDown = () => {
    let _totalHeight = document.body.offsetHeight;
    window.scrollTo({ top: _totalHeight, behavior: "smooth" });
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const Locationon = () => {
    alert("Please Turn Your Browser Location On.111");
    window.location.reload();
  };

  useEffect(() => {
    if (getNewReport) {
      setAllCrewMemData(s.state.getCrewLeadData);
      setForm_template_API(s.state.ijhaform);
      var _latlng;
      if (getFormData.location_required === true) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            _latlng = _.cloneDeep(getLoc);
            _latlng.lat = position.coords.latitude;
            _latlng.lng = position.coords.longitude;
            setLoc(_latlng);
          });
        }
      }
      if (s.state.ijhaform.complete_status === "InProgress") {
        let _getSelectedSection = s.state.ijhaform.form_template.map((obj) => {
          return {
            sectionId: obj.sectionId,
            sectionName: obj.sectionName,
            checked_val: obj.visibility,
          };
        });
        setSelectedSection(_getSelectedSection);
        setAllCrewMemData(s.state.getCrewLeadData);
        setForm_template_API(s.state.ijhaform);
        setIsAddCrew(true);
        setProceed(s.state.ijhaform.inProgressSection);
      }
    }
  }, []);

  const validateCrewMember = (_getCrewLeadData) => {
    if (
      _getCrewLeadData.crewmemberdata.filter((i) => i.employeeId).length === 0
    ) {
      _getCrewLeadData.crewmemberdata = [];
    } else {
      _getCrewLeadData.crewmemberdata = _getCrewLeadData.crewmemberdata.filter(
        (i) => i.employeeId !== "" && i.employeeId !== undefined
      );
    }
    return _getCrewLeadData;
  };

  const validateForm = (_getForm, _getCrewLeadData) => {
    let getForm = _.cloneDeep(_getForm);
    const indexWithSection = _getForm.form_template
      .map((item, index) => (item.hasOwnProperty("section") ? index : -1))
      .filter((index) => index !== -1);
    if (
      _getForm.form_template[indexWithSection[0]].section.length >
      _getCrewLeadData.crewmemberdata.length + 1
    ) {
      getForm.form_template = getForm.form_template.map((obj, k) => {
        if (!_.isEmpty(obj.section)) {
          const desiredLength =
            _getCrewLeadData.crewmemberdata.filter((i) => i.emailId).length + 1;
          const elementstoremove = obj.section.length - desiredLength;
          obj.section.splice(1, elementstoremove);
        }
        return obj;
      });
    } else {
      getForm.form_template = getForm.form_template.map((obj) => {
        if (!_.isEmpty(obj.section)) {
          for (
            let i = 0;
            i < _getCrewLeadData.crewmemberdata.filter((i) => i.emailId).length;
            i++
          ) {
            obj.section[i + 1] = _.cloneDeep(obj.section[0]);
          }
        }
        return obj;
      });
    }

    let _checkClimbMember = _getCrewLeadData.crewmemberdata.map(
      (i, k) => i.membertype
    );
    let _checkClimber = [_getCrewLeadData.membertype, ..._checkClimbMember];

    getForm.form_template.map((obj) => {
      if (obj.sectionName === "Personal Protective Equipment") {
        obj.section.map((s_obj, k) => {
          s_obj.questions.map((ss_obj) => {
            if ("image_metadata" in ss_obj && _checkClimber[k] === "Climber" && ss_obj.category !== "safety-vest") {
              ss_obj.state = "Required";
            }
          });
        });
      }
    });

    return getForm;
  };

  const handleCloseModal = () => {
    setShowGMap(false);
  };

  const handleLocationSelection = (latlng) => {
    setSelectedLocation(latlng);
    setLoc(latlng);
  };

  const validatePPE = (_getForm, _getCrewLeadData) => {
    let getForm = _.cloneDeep(_getForm);
    let _checkClimbMember = _getCrewLeadData.crewmemberdata.map(
      (i, k) => i.membertype
    );
    let _checkClimber = [_getCrewLeadData.membertype, ..._checkClimbMember];
    getForm.form_template.map((obj) => {
      if (obj.sectionName === "Personal Protective Equipment") {
        obj.section.map((s_obj, k) => {
          s_obj.questions.map((ss_obj) => {
            if ("image_metadata" in ss_obj && _checkClimber[k] === "Climber" && ss_obj.category !== "safety-vest") {
              ss_obj.state = "Required";
            }
          });
        });
      }
    });

    return getForm;
  };

  const validateSubmitForm = async (_getForm, _getCrewLeadOldData) => {
    let getForm = _.cloneDeep(_getForm);
    const uniqueEmails = new Set();
    _getCrewLeadOldData.crewmemberdata =
      _getCrewLeadOldData.crewmemberdata.filter((member) => {
        if (member.emailId && !uniqueEmails.has(member.emailId)) {
          uniqueEmails.add(member.emailId);
          return true;
        }
        return false;
      });
    if (
      _getCrewLeadOldData.crewmemberdata.filter((i) => i.emailId).length === 0
    ) {
      _getCrewLeadOldData.crewmemberdata = [];
    } else {
      _getCrewLeadOldData.crewmemberdata =
        _getCrewLeadOldData.crewmemberdata.filter(
          (i) => i.emailId !== "" && i.emailId !== undefined
        );
    }

    const section_count =
      getForm.form_template.filter((i) => i.section)[0].section.length - 1;
    const crew_members = _getCrewLeadOldData.crewmemberdata.length;
    const new_crew_members = _getCrewLeadOldData.crewmemberdata.filter(
      (i) => !i.employeeId
    ).length;
    if (section_count === crew_members && new_crew_members === 0) {
      getForm = await validatePPE(getForm, _getCrewLeadOldData);
    } else if (section_count < crew_members) {
      const _getFormData = await getFormIjhaApi(
        parseInt(getForm.formId),
        getLoc,
        setLoading,
        setErrMsg
      );
      getForm.form_template = getForm.form_template.map((obj, k) => {
        if (!_.isEmpty(obj.section)) {
          for (let i = 0; i < new_crew_members; i++) {
            obj.section[obj.section.length] = _.cloneDeep(
              _getFormData.data.form_template[k].section[0]
            );
          }
        }
        return obj;
      });
      getForm = validatePPE(getForm, _getCrewLeadOldData);
    } else if (section_count > crew_members) {
      getForm.form_template = getForm.form_template.filter((obj, k) => {
        if (!_.isEmpty(obj.section)) {
          obj.section = obj.section.filter((s_obj, i) => {
            if (i === 0) {
              return s_obj;
            } else {
              if (
                _getCrewLeadOldData.crewmemberdata
                  .map((j) => j.employeeId)
                  .includes(s_obj.employeeId)
              ) {
                return s_obj;
              }
            }
          });
        }
        return obj;
      });
      getForm = validatePPE(getForm, _getCrewLeadOldData);
    } else if (
      section_count === crew_members &&
      new_crew_members === section_count
    ) {
      const _getFormData = await getFormIjhaApi(
        parseInt(getForm.formId),
        getLoc,
        setLoading,
        setErrMsg
      );
      getForm.form_template = getForm.form_template.map((obj, k) => {
        if (!_.isEmpty(obj.section)) {
          for (let i = 0; i < new_crew_members; i++) {
            obj.section[i + 1] = _.cloneDeep(
              _getFormData.data.form_template[k].section[0]
            );
          }
        }
        return obj;
      });
      getForm = validatePPE(getForm, _getCrewLeadOldData);
    }

    return getForm;
  };

  const UpdateEAPSec = (data) => {
    let _form_template = _.cloneDeep(data);
    _form_template.map((i) => {
      if (
        i.sectionName === "Emergency Action Plan - EAP" ||
        i.sectionName === "Site Specific Rescue Plan"
      ) {
        i.questions.map((j) => {
          if ("subQuestion" in j) {
            j.subQuestion.map((item, key) => {
              if (key > 0) {
                if (item.options.length > 1) {
                  item.answer = item.options[key];
                } else {
                  item.answer = item.options[0];
                  item.options.push("Other");
                }
              } else {
                item.answer = item.options[0];
              }
            });
          }
        });
      }
    });
    return _form_template;
  };

  const getIJHForm = async (_getCrewLeadData, _getCrewLeadOldData) => {
    if (!getNewReport) {
      console.log("dasjhdjashdjkhas", s);
      let _form_template;
      if (!getCrewUpdate) {
        let _getFormData = await getFormIjhaApi(
          s.state.formID,
          s.state.getLoc,
          setLoading,
          setErrMsg
        );

        _getFormData.data.form_template = await UpdateEAPSec(
          _getFormData.data.form_template
        );
        _form_template = _.cloneDeep(_getFormData.data);
      } else {
        _form_template = _.cloneDeep(form_template);
      }

      const getCrewLeadData = await validateCrewMember(_getCrewLeadData);
      const _getForm = await validateForm(_form_template, getCrewLeadData);
      let _getSelectedSection = _getForm.form_template.map((obj) => {
        return {
          sectionId: obj.sectionId,
          sectionName: obj.sectionName,
          checked_val: obj.visibility,
        };
      });
      _getForm.form_template = await get911AddressInfoApi(
        setForm_template_API,
        _getForm.form_template,
        s.state.getLoc,
        setLoading
      );

      setSelectedSection(_getSelectedSection);
      setAllCrewMemData(getCrewLeadData);
      setForm_template_API(_getForm);
      setIsAddCrew(true);
      setLoading(false);
    } else {
      const getCrewLeadData = await validateCrewMember(_getCrewLeadData);
      const _getForm = await validateSubmitForm(
        form_template,
        _getCrewLeadOldData
      );
      let _getSelectedSection = _getForm.form_template.map((obj) => {
        return {
          sectionId: obj.sectionId,
          sectionName: obj.sectionName,
          checked_val: obj.visibility,
        };
      });
      _getForm.form_template = _getForm.form_template.map((obj) => {
        if (!_.isEmpty(obj.section)) {
          obj.section.map((i, k) => {
            if (k !== 0) {
              i.employeeId = getCrewLeadData.crewmemberdata[k - 1].employeeId;
            }
          });
        }
        return obj;
      });
      if (getFormData.location_required === false) {
        const newLocationchange = {
          lat: getFormData.default_location.lat,
          lng: getFormData.default_location.long,
        };
        _getForm.form_template = await get911AddressInfoApi(
          setForm_template_API,
          _getForm.form_template,
          newLocationchange,
          setLoading
        );
      } else {
        _getForm.form_template = await get911AddressInfoApi(
          setForm_template_API,
          _getForm.form_template,
          getLoc,
          setLoading
        );
      }

      setSelectedSection(_getSelectedSection);
      setAllCrewMemData(getCrewLeadData);
      setForm_template_API(_getForm);
      setIsAddCrew(true);
      setLoading(false);
    }
  };

  return (
    <Container className="margin-container">
      {showGMap && (
        <ManualGMap
          center={0}
          setLatLng911={handleLocationSelection}
          showModal={showGMap}
          closeModal={handleCloseModal}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      )}
      {!getIsAddCrew ? (
        <AddCrewMemberDynamic
          setLoc={setLoc}
          getFormData={getFormData}
          getIJHForm={getIJHForm}
          getNewReport={getNewReport}
          getAllCrewMemData={getAllCrewMemData}
          getLoc={getLoc}
          setShowGMap={setShowGMap}
          valreq={s.state.ijhaform}
        />
      ) : (
        <SelectedSectionFormforIJHA
          getLoc={getLoc}
          setLoc={setLoc}
          getLocationavailable={getLocationavailable}
          setLocationavailable={setLocationavailable}
          selectedSectionData={getSelectedSection}
          form_template_p={form_template}
          getNewReport={getNewReport}
          // _getLoc={getLoc}
          getAllCrewMemData={getAllCrewMemData}
          FormData={getFormData}
          setIsAddCrew={setIsAddCrew}
          setCrewUpdate={setCrewUpdate}
          setForm_template_API={setForm_template_API}
          _setProceed={setProceed}
          _getProceed={getProceed}
        />
      )}

      {getLoading && <Loading width="32" height="32" />}
      <Button
        onClick={scrollDown}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed topToBottom end-0 translate-middle "
        value="bottom"
        id="back-to-down"
      >
        <img
          src={String(arrow_up)}
          alt="scroll bottom"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
      <Button
        onClick={scrollUp}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed bottom-0 end-0 translate-middle "
        value="top"
        id="back-to-up"
      >
        <img
          src={String(arrow_up)}
          alt="scroll top"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
    </Container>
  );
}
