import React, { useState, useEffect } from "react";
import { GetDropdownValues } from "../../api/DropdownValues.api";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";
import _ from "lodash";

function DropdownForSubQuestion({
  s_obj,
  onChangeFormTemplate,
  sectionId,
  questions_obj,
  handleDropdownValueChange,
  setEmailError,
  SelectedOptionofDropdown,
  setSelectedOptionofDropdown,
  getEmployeeDropdownval
}) {
  const [dropdownoptions, setdropdownoptions] = useState([]);
  // const [countryId, setdropdownID] = useState("");

  const [BlankTextField, setBlankTextField] = useState(false);
  const [newDropdownValue, setnewDropdownValue] = useState("");
  const [EmailErrorrRequired, setEmailErrorRequired] = useState("");

  useEffect(() => {
    if (SelectedOptionofDropdown) {
      handleDropdownValueChange(SelectedOptionofDropdown);
    }
  }, [SelectedOptionofDropdown]);

  const param1 = {
    filter: s_obj.userFilterSuggestion,
  };

  useEffect(() => {
    const getApiResponse = async () => {
      const response = await GetDropdownValues(param1);
      const getRes = await response;
      setdropdownoptions(await getRes);
    };
    getApiResponse();

    if(s_obj.answer.includes("Other")){
      setBlankTextField(true);
      setnewDropdownValue(s_obj.answer.split('-')[1]);
    }
  }, []);

  const handleDropdownChange = (SelectedOptionofDropdown) => {
    setnewDropdownValue("");
    if (!BlankTextField) {
      setSelectedOptionofDropdown(null);
    }
    if (SelectedOptionofDropdown.value === "Other") {
      setEmailError("Please enter a valid email address");
      setSelectedOptionofDropdown(SelectedOptionofDropdown);
      setBlankTextField(true);
      onChangeFormTemplate({
        sectionId,
        answer: newDropdownValue,
        questionId: questions_obj.questionId,
        subQuestionId: s_obj.questionId,
        updateSubQuestion: true,
        visibility: true,
      });
    } else {
      setEmailError("");
      setSelectedOptionofDropdown(SelectedOptionofDropdown);
      setBlankTextField(false);
      const emailRegex = /<(.+)>/;
      const match = SelectedOptionofDropdown.value.match(emailRegex);
      const email = match ? match[1] : SelectedOptionofDropdown.value;

      const _showHideEmpDropDown = getEmployeeDropdownval.filter(i => i.company === SelectedOptionofDropdown.value);
      if (!_.isEmpty(_showHideEmpDropDown)) {
        onChangeFormTemplate({
          sectionId,
          answer: email,
          questionId: questions_obj.questionId,
          subQuestionId: s_obj.questionId,
          updateSubQuestion: true,
          visibility: false,
        });
      } else {
        onChangeFormTemplate({
          sectionId,
          answer: email,
          questionId: questions_obj.questionId,
          subQuestionId: s_obj.questionId,
          updateSubQuestion: true,
          visibility: true,
        });
      }

      // localStorage.setItem('SelectedOptionofDropdown', JSON.stringify(SelectedOptionofDropdown))
    }
  };

  // useEffect(() => {
  //   if (BlankTextField) {
  //     onChangeFormTemplate({
  //       sectionId,
  //       answer: newDropdownValue,
  //       questionId: questions_obj.questionId,
  //       subQuestionId: s_obj.questionId,
  //       updateSubQuestion: true,
  //       visibility: true,
  //     });
  //   }
  // }, [BlankTextField]);

  const handleAddnewValue = (e) => {

    const emailPattern = /^\S+@\S+\.\S+$/; // Regex pattern to validate email
    if (
      s_obj.emailValidation === "Yes" &&
      !emailPattern.test(newDropdownValue)
    ) {
      setEmailError("Please enter a valid email address");
      setEmailErrorRequired("Please enter a valid email address");
      return;
    }

    // const updateDropdown = [...dropdownoptions, newDropdownValue];
    // setdropdownoptions(updateDropdown);
    // setSelectedOptionofDropdown({
    //   value: newDropdownValue,
    //   label: newDropdownValue,
    // });
    // setnewDropdownValue("");
    // setBlankTextField(false);
    onChangeFormTemplate({
      sectionId,
      answer: "Other-"+e.target.value,
      questionId: questions_obj.questionId,
      subQuestionId: s_obj.questionId,
      updateSubQuestion: true,
    });
    setEmailError("");
    setEmailErrorRequired("");
    // localStorage.setItem('SelectedOptionofDropdownApi', JSON.stringify({ value: newDropdownValue, label: newDropdownValue }))

  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      fontSize: 14,
    }),
  };

  const options = [
    ...dropdownoptions.map((option) => ({ value: option, label: option })),
  ];

  // useEffect(() => {

  // }, [SelectedOptionofDropdown])
  return (
    <>
      <Form.Label>
        <span className="text-danger bold-font">
          {s_obj.state === "Required" ? "*" : ""}
        </span>
        {s_obj.question}
      </Form.Label>
      <Select
        options={options}
        value={
          s_obj.answer === ""
            ? SelectedOptionofDropdown
            : { value: s_obj.answer.includes('Other') ? s_obj.answer.split('-')[0] : s_obj.answer, label: s_obj.answer.includes('Other') ? s_obj.answer.split('-')[0] : s_obj.answer }
        }
        // value={SelectedOptionofDropdown}
        onChange={handleDropdownChange}
        styles={customStyles}
        placeholder="--Select --"
        isSearchable
      />
      <br />
      {BlankTextField && (
        <div>
          <Form.Control
            type="text"
            placeholder="Please enter Other company name"
            value={newDropdownValue}
            onChange={(e) => { setnewDropdownValue(e.target.value); handleAddnewValue(e) }}
            isInvalid={!!EmailErrorrRequired} // Set the isInvalid prop based on the emailError state variable
          />
          {/* <Button
            onClick={handleAddnewValue}
            style={{ marginLeft: "10px", alignSelf: "flex-end" }}
          >
            Add
          </Button> */}
        </div>
      )}
      <Form.Control.Feedback type="invalid">
        {EmailErrorrRequired}
      </Form.Control.Feedback>
      <br />
    </>
  );
}

export default DropdownForSubQuestion;
